.breackcum-introbody{
    background-color: rgb(132, 12, 22,.54);
    height: 55px;
    margin-top: -55px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
}
.breackcum-introbody ul{
    padding: 0;
    list-style: none;
    margin: 0;
    display: inline;
}
.breackcum-introbody li{
    display: inline;
    margin-right: 32px;
    color: #fff;
    font: normal normal normal 16px/21px SFUFuturaBook;
}
.section1-bg{
    background-color: rgb(132, 12, 22);
    height: 791px;
    overflow: hidden;
    position: relative;
    top: -1px;
}
.img-section1{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-radius: 50%;
    width: 100%;
    height: 1140px;
    overflow: hidden;
    position: relative;
}

.section1-bg span{
    right: 0;
    top: 19%;
    position: absolute;
    display: block;
    width: 471px;
    text-indent: 51px;
    color: #FFBE00;
    font: normal normal bold 30px/37px Montserrat-Bold;
}
.section1-bg .groupimg{
    position: absolute;
    top: 45%;
    left: 3%;
    width: 439px;
    height: 217px;
}
.section2-intro .firstP-section2{
    
    width: 100%;
    text-align: center;
    font: normal normal normal 20px/30px Montserrat-Regular;
    color: #707070;
    padding: 55px 0;
}
.section2-intro{
    position: relative;
} 
span .upperBoldText{
    color: #840C16;
    display: inline;
    font: normal normal 600 20px/30px Montserrat-SemiBold;
}
.section2-intro .imgcircle{
    margin-bottom: 15px;
    width: 74px;
    height: 74px;
    border-radius: 50%;
    border: 3px solid #FCDE9E;
    overflow: hidden;
}
.section2-intro .imgcircle > img{
    position: relative;
    width: 110%;
    top: -3px;
    left: -3px;
    right: -3px;
}
.col-section2{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.AnhHai{
    font-family: muli, sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #840C16;
    letter-spacing: 2px;
    margin-bottom: 6px;
    font-size: 15px;
    text-align: center;

}
.AnhHai-title{
    letter-spacing: 0px;
    color: #840C16;
    text-transform: uppercase;
    opacity: 0.57;
    text-align: center;
    font: normal normal normal 12px/15px Muli;
    font-family: muli, sans-serif;
    font-style: normal;
    font-size: 12px;
}
.secondP-row1 , .secondP-row2, .secondP-row3{
    display: flex;
    align-items: flex-start;
    margin-bottom: 22px;
}
.secondP{
    width: 75%;

}
.secondP-section2{
    font: normal normal normal 18px/30px Montserrat-Regular;
    color: #392A00;
}
.secondP .heading {
    font: normal normal normal 35px/44px UTMAlterGothic;
    color: #840C16;
    padding-bottom: 21px;
}
.secondP .bodying, .Montserrat-Regular1830 {
    font: normal normal normal 18px/30px Montserrat-Regular;
    color: #392A00;
}
.img-ab-section2{
    z-index: -1;
    opacity: .9;
    position: absolute;
    bottom: 13%;
    right: -22%;
}

/* section 3 */


.section3-intro{
    margin-top: 61px;
}
.headingSection3{
    width: 100%;
}
.firstP-section3{
    padding-top: 61px;
    margin: 0 auto;
    /* width: 90%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.firstP-section3 .normal-text{
    margin-top: 40px;
    text-indent: 46px;
    text-align: center;
    font: normal normal 30px/37px Montserrat-Bold;
    font-weight: 900;
    letter-spacing: 0px;
    color: #840C16;
}
.firstP-section3 .color-text{
    display: inline;
    font: bold 40px / 37px Montserrat-Bold;
    color: rgb(255, 190, 0);
}
.secondP-section3{
    margin-top: 37px;
}
.thirdP-section3{
    margin-top: 37px;
}
.fourthP-section3{
    margin: 61px 0;
}
.fourthP-section3 > span{
    text-indent: 26px;
    padding: 0;
    color: #392A00;
    font: italic 18px/30px Montserrat-BoldItalic;
    text-align: right;
}

.wrapimg-sm-boss{
    position: relative;
    height: 157px;
    width: 157px;
    margin-left: auto;
    overflow: hidden;
    border-radius: 50%;
}
.wrapimg-sm-boss > img{
    position: absolute;
    top: 0;
    left: 0;
}
.wrap-nameboss > .name{
    font: normal normal bold 9px/11px Muli;
    letter-spacing: 1.75px;

    color: #FCDE9E;
    text-align: left;
}
.wrap-nameboss{
    z-index: 5;
    width: 105px;
    position: absolute;
    right: 11px;;
    top: 125px;
    height: 20px;
}
.wrap-nameboss > .title{
    font: normal normal bold 6px/8px Muli;
    color: #840C16;
    text-align: right;
}
/* car */
.slick-slide > div{
    display: flex;
    height: 100%;
}
.slick-list, .slick-track{
    height: 100%;
}
.slick-initialized .slick-slide {
    margin: 0 7px;
}
.col1-Footer{
    position: relative;
}
.col1-Footer > div{
    color: #FFBE00;
    padding: 0 20px;
    bottom: 20px;
    position: absolute;
}
.col2-Footer{
    height: 100%;
    padding: 7px;
     background-color: #FFFAF0;
}
.col2-Footer > span {
    margin: 8px;
}
.col3-Footer div{
    background-color: #FFFAF0;
    padding: 13px;
}
.col3-Footer > .row2{
    margin: 15px 0;
}
.col2-Footer > .head, .col3-Footer .head{
    font: normal normal normal 20px/25px UTMAlterGothic;
    color: #840C16;
}
.col2-Footer > .title, .col3-Footer .title{
    font: normal normal normal 13px/20px Montserrat-Regular;
    color: #392A00;
}
.col2-Footer > .time, .col3-Footer .time{
    font: normal normal normal 10px/20px Montserrat-Regular;
    color: #FCDE9E;
}
.col3-Footer .title{
    padding: 8px 0;
}
.doctiep{
    display: inline;
    color: #840C16;
    font: normal normal bold 13px/18px Montserrat-Bold;
}
.col1-Footer,.col2-Footer,.col3-Footer, .row1, .row2, .row3{
    border-radius: 5px;
}
.footer-custom .title-head{
    font: normal normal normal 53px/66px UTMAlterGothic;
    color: #840C16;
}
.footer-custom .title-more{
    font: normal normal normal 12px/20px Montserrat-Regular;
    color: #FFFAF0;
    line-height: 66px;
    cursor: pointer;
}
.section1-img-family-phone
    {
        display:none
        
    }
.section3-intro{
    margin-bottom: 318px;
}
@media (max-width:755px)
{
   
    .section1-bg{
        height: 330px;
    }
    .img-section1{
       
        height:384px;
       
       
    }
    .section1-bg span{
        right: 0;
        top: 3%;
        display: block;
        width: 170px;
        text-indent: 20px;
        font: normal normal bold 20px/30px Montserrat-Bold;
    }
    .groupimg-banhcuon
    {
        width: 70px;
        padding: 0px;
        margin-left: 3%;
    }
    .groupimg-icon
    {
        width:25.13px;
        margin-left: 50px;
        margin-top: 6px;
        
    }
    .groupimg-logo{
        width:150px;
        height:50px;
        padding: 0px;
    }
    .section1-bg .groupimg{
        position: absolute;
        top: 47%;
        left: 8%;
        width: 200px;
    }
    .section1-img-family-desktop
    {
        display:none
        
    }
    .section1-img-family-phone
    {
        display:block
        
    }
    .section2-intro .firstP-section2{
    
      
        font: normal normal normal 15px/22px Montserrat-Regular;
        color: #707070;
        
    }
    .secondP-section2{
        font: normal normal normal 15px/22px Montserrat-Regular;
    }
    .img-ab-section2{
        bottom: 13%;
        right: -60%;
    }
    .firstP-section3 .normal-text{
        margin-top: 40px;
        text-indent: 0px;
        text-align: center;
        font: normal normal 20px/30px Montserrat-Bold;
        font-weight: 900;
        letter-spacing: 0px;
        color: #840C16;
    }
    .firstP-section3 .color-text{
        
        font: bold 25px / 30px Montserrat-Bold;
        
    }
    .secondP-section3{
        font: normal normal normal 15px/22px Montserrat-Regular;
    }
    .thirdP-section3{
        font: normal normal normal 15px/22px Montserrat-Regular;
    }
    .fourthP-section3{
        margin: 61px 0;
        
    }
    .fourthP-section3 > span{
        font: italic 18px/30px Montserrat-BoldItalic;
    }
    .wrapimg-sm-boss{
        
        width: 157px;
        margin: 0 auto;
        margin-bottom: 50%;
     
    }
  
   
    .Footerimg{
       display:none
    }
    .col3-Footer
    {
        overflow-x: auto;
        display: flex;
        max-width: 100%;
    }
    .col3-Footer div {
        background-color: #FFFAF0;
        padding: 13px;
        flex-shrink: 0;
        /* height: 200px; */
        width: 100vw;
        margin-right: 20px;
        
    }
    .col3-Footer > .row2{
        margin: 0px 0;
        margin-right: 20px;
    
    }
    .iframefooter
    {
        height:572px;
        padding:10px
    }
    .title-more{
        display:none
    }
    .footer-custom .title-head{
        font: normal normal normal 30px/40px UTMAlterGothic;
        padding:10px
    }
    .section3-intro{
        margin-bottom: -318px;
    }
}