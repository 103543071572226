.New
{
    background: #840C16 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    width: 274px;
    height: 92vh;
    
}
.bodyNews
{
    margin-top: 100px;
}
@media (min-width: 700px) {
    .news-pl{
        padding-left: 40px;
    }
    .news-re-mobile > div:nth-child(2){
        padding-left: 36px;
    }
    .mobilenew
    {
        display: none;
    }
}
@media (max-width: 755px)
{
    .img1{
        height:269px
    }
}
.TitileNews
{
    margin-top: 30px;
    text-align: left;
    font: normal normal normal 53px/66px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840C16;
    opacity: 1
}
.Review
{
    text-align: left;
    font: normal normal normal 25px/31px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840C16;
    opacity: 1;
    border-left: 5px solid;
    border-color:#FFBE00 ;
    padding-left:20px
}
.wrap-News{
    margin-bottom: 470px;
}
.Text1
{
    text-align: left;
    font: normal normal normal 18px/30px Montserrat;
    letter-spacing: 0px;
    color: #392A00;
    opacity: 1;
}
.Commnet
{
    text-align: left;
    font: italic normal 600 18px/30px Montserrat;
    letter-spacing: 0px;
    opacity: 1
}
.NameNews
{
    text-align: left;
    font: normal normal normal 15px/19px UTMAlterGothic;
    letter-spacing: 0px;
    color: #FFBE00;
    opacity: 1;
}
.Titile
{
    text-align: center;
    font: normal normal bold 18px/30px Montserrat;
    letter-spacing: 0px;
    color: #FCDE9E;
    text-transform: uppercase;
    opacity: 1;
}
.allNew
{
    text-align: left;
    font: normal normal normal 15px/20px Montserrat;
    letter-spacing: 0px;
    color: #FCDE9E;
    opacity: 1;
    
}
.DateNew
{
    text-align: left;
    font: normal normal bold 8px/12px Montserrat;
    letter-spacing: 0px;
    color: #FCDE9E;
    opacity:1
}
.slideNewsDetail
{
    height:75vh;
    overflow-y: auto;
    position: relative;
}
.Commnet img{
    width: 100%;
}
.Commnet
{
    font: inherit;
}

#video
{
    width: 100% !important;
}