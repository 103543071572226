.Header
{
    position: fixed;
    width: 100%;
    text-align: left;
    font: normal normal normal 16px/21px SFUFuturaBook;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    z-index:2000;
    
    align-items: center ;
    
    
}
.Header.slidedow{
    position: fixed;
    /* top: 0;
    left: 0;
    right: 0; */
    background-color: #EBEDEB;
    margin-left: 0;
    align-items: center ;
    opacity: 0.8;
    padding-right: 5px;
}
.nav-link.show
{
    color: black;
}
.nav-link
{
    color: white;
}
.nen{
    position: relative;
    background-image: url("./img/nen.webp");
    width: 100%;
    height: calc(100vh + 100px);
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed; */
    background-position: center;
}
.nen01{
    position: relative;
    background-image: url("./img/nen01.webp");
    width: 100%;
    height: calc(100vh + 100px);
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed; */
    background-position: center;
}
@media (min-width :756px) and (max-width:1650px) {
    .Nav-Phone
    {
        display: none;
    }
    .icon
    {
        display: none;
    }
  }
@media (min-width:1650px) {
    .Nav-Phone
    {
        display: none;
    }
    .icon
    {
        display: none;
    }
}
.color
{
    position: absolute;
    background: #000000 0% 0% no-repeat padding-box;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity:0.55;
    z-index: 1;
}
.color-no-touch{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity:0.55;
    z-index: 1;
}
.myText-carousel{
    position: absolute;
     top: 40vh;
     left: 10%;
     z-index: 2;
}
@keyframes textUpBCQ{
    0%{
        opacity: 0;
        transform: translateY(30px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
.Nav-Home
{
    position: sticky;
    z-index:1000;
    font: normal normal normal 16px/21px SFUFuturaBook;
    color: #FFFFFF;
    opacity: 1;
    font-size: 20px;
    
    
}
#demo
{
    
}
.history{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 33px/50px var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal normal 33px/50px Montserrat-Regular;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    z-index: 1000;
    animation: textUpBCQ 1s ease-in
}
.text
{
    margin-top: 16px;
    text-align: left;
    font: normal normal normal 15px/30px Montserrat-Regular;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    z-index: 100;
    animation: textUpBCQ 1.2s .2s ease-in-out;
}
/* .car-header{
    po
} */
.imgZoom{
    width: 100%;
    animation: zoomIn 6s ease-out;
   
}
@keyframes zoomIn {
    0%{
          transform: scale(1,1);
          opacity: .8;
    }
    100%{
        transform: scale(1.1,1.1);
        opacity: 1;
    }
    
}
@media (max-width:755px) {
    .Nav-Home
    {
        display: none;
    }
}
.logoHeader
{
    width:128px;
   
}
