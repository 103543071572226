@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;1,600&display=swap");
.contentfooter {
    background-image: url(./img/backgroundFooter.webp);
    background-repeat: no-repeat;
    width: 100%;
    /* height: 1366px; */
    opacity: 1;
    background-size: cover;
    position: relative;
    /* top: -471px; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
}

.contactFooter {
    padding: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* position: absolute; */
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 13px;
}
.logo-footer {
    position: absolute;
    left: 3%;
}
.logoQ {
    visibility: hidden;
}
.contact-address {
    left: 15%;
    width: 25%;
    position: absolute;
}

.shop2 {
    top: 17.8%;
    width: 25%;
    position: absolute;
    left: 42%;
}

.like-facebook {
    position: absolute;
    top: 90px;
    left: 74%;
    width: 20%;
    height: 166px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.borderboxbottom {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 57px;
    height: 46px;
    border-bottom: 5px solid #840c16;
    border-right: 5px solid #840c16;
    border-bottom-right-radius: 5px 5px;
}

.shadow-contact {
    position: absolute;
    bottom: -15px;
    left: -15px;
    width: 1140px;
    height: 287px;
    background: #ffbe00 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 0.78;
    z-index: -1;
}

.infor-contact {
    margin-top: 69px;
    display: flex;
    align-items: center;
    /* position: absolute; */
    /* bottom: 15px;
    left: 15px; */
    text-align: center;
    position: relative;
    height: 287px;
    background: #fcde9e 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
}

.copyright {
    margin-top: 54px;
    margin-bottom: 69px;
    text-align: center;
    font: normal normal 600 16px/30px Montserrat-Regular;
    letter-spacing: 0px;
    color: #840c16;
    opacity: 1;
}

.contact-address>.contact-header {
    text-align: left;
    font: normal normal normal 30px/38px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840c16;
    opacity: 1;
    margin-bottom: 21px;
}

.shop2>.contact-header {
    height: 37px;
    text-align: left;
    font: normal normal normal 20px/25px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840c16;
    opacity: 1;
}

.contact-body {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
}

.contact-body>span {
    text-align: left;
    font: normal normal normal 15px/21px SFUFuturaBook;
    letter-spacing: 0px;
    color: #392a00;
}

.strong-text {
    display: inline;
    text-align: left;
    font: normal normal bold 15px/21px SFUFuturaBold;
    letter-spacing: 0px;
    color: #392a00;
}

.breaking-news {
    position: relative;
}

.carousel-inner::-webkit-scrollbar{
    width: 0 !important;
    display: none;
}
.img-carousel {
    height: 166px;
    border-radius: 5px;
    width: 50%;
}

.text-news {
    padding: 0 10px;
    display: block;
    /* width: 100%; */
    /* max-width: 187px; */
    height: 100%;
    max-height: 139px;
    text-align: left;
    font: normal normal normal 13px/20px Montserrat-Regular;
    letter-spacing: 0px;
    color: #392a00;
}

.mybtn-pre {
    width: 19px;
    position: absolute;
    left: -81px;
}

.mybtn-next {
    width: 19px;
    position: absolute;
    right: -81px;
}

.text-news-header {
    font: normal normal normal 20px/25px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840c16;
 
    line-height: 1.5rem;
    height: 4.5rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.text-news-body {
    margin-top: 6px;
    width: 100%;
    
    align-items: 'center';
    justify-content: 'center';
    font: normal normal normal 13px/20px Montserrat-Regular;
    letter-spacing: 0px;
    color: #392a00;
}

.text-news-date {
    font: normal normal bold 13px/20px Montserrat-Bold;
    letter-spacing: 0px;
    color: #fcde9e;
}

/* rating */

.rating {
    background-image: url(./img/rating01.webp);
    width: 100%;
    height: 100%;
    position: absolute;
}

.rating-card img {
    width: 100%;
    height: 100%;
}

.tintucmoinhat {
    color: var(--unnamed-color-840c16);
    text-align: left;
    font: normal normal normal 53px/66px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840c16;
    opacity: 1;
    padding: 0;
    margin-bottom: 77px;
}

.contact-rowtext>span {
    display: inline;
}

.text-news-body {
    font-size: 9px;
    line-height: 1.5rem;
    height: 4.5rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

}

.text-news {
    height: 13.75%;
}

.text-news-date {
    font-size: 8px;
}

.CommentCarousel {
    width: 400px;
    position: absolute;
    top: 10%;
    right: 9%;
    text-align: left;
    font: normal normal normal 18px/30px Montserrat-Regular;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    z-index: 100;
}

.wrap-tintucmoinhat {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 477px;
}

.slide-mobie-footer {
    display: none;
}

.slide-mobie-footer ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    list-style: none;
    padding-left: 0;
    position: relative;
    max-width: 100%;
    margin-left: 13px;
    overflow-x: auto;
    overflow-y: hidden;
    height: 220px;
}

.slide-mobie-footer li {
    display: flex;
    flex-shrink: 0;
    padding-right: 8px;
    width: 350px;
}

.fisrtimgs img:nth-child(1) {
    padding-bottom: 30px;
}

.fisrtimgs img:nth-child(2) {
    padding-bottom: 30px;
}

.fisrtimgs img:nth-child(3) {
    padding-bottom: 30px;
}

.fix-imgFooter {
    display: none;
    position: absolute;
    width: 900px;
    top: -117px;
    height: 463px;
}

.slide-pc-footer
{
    margin-top: 400px;
}
.slideFooter ul{
    padding: 0;
    display: flex;
    list-style: none;
    padding-left: 0;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    height: 200px;
    width: 100%;
    align-items: center;
    margin:0 auto;
    margin-left: 70px;
}
.slideFooter ul::-webkit-scrollbar{
    display: none;
}
.slideFooter
{
    display: flex;
    align-items: center;

}
@media (min-width: 1200px) and (max-width: 1650px) {
    .contentfooter {
        margin-top: -420px;
    }
}

@media (min-width: 1200px) and (max-width: 1482px) {
    .wrap-container {
        padding-left: 30px;
    }
    .ct-logo {
        right: 0;
    }
}

@media (min-width: 1649px) {
    .contentfooter {
        margin-top: -530px;
    }
}

@media (max-width: 755px) {
    .ct-multiRow .fisrtimgs img{
        height: 140px;
    }
    .news-re-mobile {
        flex-direction: column-reverse;
    }
    .wrap-News {
        margin-bottom: -200px !important;
    }
    .Commnet,
    .Text {
        font-size: 13px !important;
    }
    .Commnet {
        line-height: 20px !important;
    }
    .TitileNews {
        font: normal normal normal 34px/51px UTMAlterGothic !important;
    }
    .news-re-mobile>div:nth-child(2) {
        padding-left: 0;
    }
    .news-re-mobile pre {
        letter-spacing: 0.5px;
        font-size: 12px !important;
    }
    .ct-intro-body .body-row1 iframe {
        height: 199px;
    }
    .ct-intro-body .body-row1 {
        margin: 0;
        padding: 0 3px;
    }
    .wrap-nameboss{
        right: 100px !important;
    }
    .fix-imgFooter {
        display: block;
    }
    .wrap-tintucmoinhat {
        display: none;
    }
    .slide-mobie-footer {
        position: relative;
        display: block;
        z-index: 10;
    }
    .wrap-boss {
        width: unset;
        height: unset;
    }
    .ct-boss {
        width: unset;
        height: 600px;
        padding-top: 36px;
    }
    .Content {
        background: unset;
    }
    .imgboss {
        height: 550px;
        width: 325px;
    }
    .ct-boss .bossbr01 {
        width: 500px;
    }
    .ct-boss .bossbr02 {
        width: 500px;
    }
    .ct-boss .bossshape {
        top: -51px;
        left: 0;
        width: 575px;
    }
    .ct-boss .boss1946 {
        top: 438px;
        left: 4px;
        bottom: 30px;
        font: normal normal normal 182px/92px UTMAlterGothic;
        text-shadow: -31px -31px 0px #00000014;
    }
    .ct-boss>.w {
        display: none;
    }
    .ct-boss .imgboss {
        left: 52px;
        width: unset;
        height: unset;
    }
    .wraptextBoss {
        width: 210px;
        height: 250px;
        left: 3px;
        top: 30px;
    }
    .ct-multiRow {
        padding: 0 3px;
    }
    .textboxboss {
        width: 210px;
        height: 250px;
        font: normal normal normal 18px/23px UTMAlterGothic;
    }
    .textboxboss>span {
        padding: 26px;
        width: unset;
        height: unset;
    }
    .textboxboss {
        top: unset;
        left: unset;
    }
    .ct-boss .borderboxtop {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 30px;
        height: 24px;
        border-top: 3px solid #840c16;
        border-left: 3px solid #840c16;
        border-top-left-radius: 3px 3px;
    }
    .ct-boss .borderboxbottom {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 30px;
        height: 24px;
        border-bottom: 3px solid #840c16;
        border-right: 3px solid #840c16;
        border-bottom-right-radius: 3px 3px;
    }
    .ct-boss .textboss {
        width: 256px;
        position: absolute;
        top: 329px;
        left: 65px;
        z-index: 3;
    }
    .fisrtimgs {
        margin: 0;
    }
    .fisrtimgs img:first-child {
        padding: 0;
        padding-right: 5px;
    }
    .fisrtimgs img:nth-child(2) {
        padding: 0;
    }
    .fisrtimgs img:nth-child(3) {
        padding: 0;
        padding-top: 5px;
        padding-right: 5px;
    }
    .fisrtimgs img:nth-child(4) {
        padding: 0;
        padding-top: 5px;
    }
    .fisrtimgs img:nth-child(5) {
        padding: 0;
        padding-top: 5px;
    }
    .ct-intro-head {
        margin-top: 15px;
    }
    .ct-intro-head>span {
        width: 94%;
        height: 100%;
        font-size: 15px;
    }
    .ct-intro-head>div {
        left: -38px;
        top: 20px;
    }
    .textrow1-head {
        line-height: 17px;
        margin-bottom: 9px;
    }
    .textrow1-body {
        font-size: 25px;
        line-height: 31px;
    }
    .wrap-textrow1 {
        padding: 0 13px 13px;
    }
    .ct-intro-body .col5-custom {
        align-items: flex-start;
    }
    .ct-intro-body .col-nth3 {
        padding: 0;
        margin: 0;
        text-align: end;
        justify-content: end;
        flex-direction: column-reverse;
    }
    .col-nth3-div {
        padding: 0 24px;
    }
    .col-nth3>div:first-child {
        left: 0;
        margin-top: 22px;
    }
    .myfiximg-01>img { 
        width: 100%;
    }
    .ct-intro-body .col5-custom {
        margin-top: 22px;
    }
    .body-row1>div:nth-child(4) {
        margin-top: 22px;
    }
    .logo-footer {
        visibility: hidden;
    }
    .infor-contact {
        height: 750px;
        width: 100%;
        background: #fcde9e 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
    }
    .shadow-contact {
        position: absolute;
        bottom: -15px;
        left: -15px;
        width: 100%;
        height: 750px;
        background: #ffbe00 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 0.78;
        z-index: -1;

    }
    .logoQ {
        position: absolute;
        visibility: visible;
        top: 3%;
        left: 30%;
        width: 35%;
        height: 12%;

    }
    .contact-address {
        align-self: flex-start;
        width: 100%;
        top: 18%;
        position: absolute;
    }
    .shop2 {
        position: absolute;
        width: 100%;
        top: 45%;
        left: 18%;
    }
    .like-facebook {
        position: absolute;
        width: 100%;
        top: 75%;
        left: 38%;
    }
    .icon-ytb-zalo {
        width: 200px;
        align-items: flex-start;
        justify-content: center;
        height: 115px;
    }
    .contact-address>.contact-header,
    .contact-body {
        width: 267px;
    }
    .copyright {
        font: normal normal 600 9px/20px Montserrat-Regular;
        margin-top: 30px;
        margin-bottom: 41px;
        height: 100px;
    }
    .logo-footer {
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .tintucmoinhat {
        font: normal normal normal 30px/38px UTMAlterGothic;
        margin-left: 13px;
        margin-bottom: 32px;
        position: relative;
        padding-top: 41px;
    }
    .CommentCarousel {
        width: 105px;
        font: normal normal normal 12px/22px Montserrat-Regular;
    }
    .footer-row1,
    .footer-row2 {
        width: unset;
    }
    .footer-row1 {
        font: normal normal 18px/27px Montserrat-Bold;
    }
    .footer-row1 span {
        display: inline;
        font: normal normal bold 22px/27px Montserrat-Bold;
        color: #ffbe00;
    }
    .ct-intro-footer>img {
        margin-bottom: 20px;
    }
    .ct-intro-footer {
        margin: 20px 5px 0;
    }
    .Content {
        margin-bottom: 30px;
    }
    .ct-intro-head .huongvidicung {
        width: 284px;
        left: 18%;
        font: normal normal bold 22px/27px Montserrat-Bold;
    }
    .ct-intro-body {
        margin-top: 50px;
    }
    .nen,
    .nen01 {
        height: 100vh;
    }
    .wrap-boss {
        margin-bottom: 30px;
        padding-left: 3px;
    }
    .ct-logo {
        display: flex;
        flex-direction: column;
    }
    .ct-logo .contenthv>span {
        font: normal normal normal 28px/35px UTMAlterGothic;
        padding: 0 18px;
    }
    .ct-logo .logo-huongvi {
        width: 231px;
        height: unset;
        margin-top: 20px;
        margin-left: 15%;
    }
    .ct-logo .contenthv {
        border-radius: 5px;
        height: 41px;
    }
    .box1hv {
        top: -7px;
        left: -7px;
        width: 27px;
        height: 21px;
    }
    .box2hv {
        bottom: -7px;
        right: -7px;
        width: 27px;
        height: 21px;
    }
    .ct-logo {
        right: 0;
        width: unset;
        padding-right: 3px;
    }
    .ct-logo .text-title {
        font: normal normal normal 12px/22px Montserrat-Regular;
        align-self: center;
        max-width: 340px;
    }
    .ct-logo .firstP-foot {
        max-width: 340px;
        margin-top: -2px;
        font: normal normal bold 20px/27px Montserrat-Bold;
    }
    .ct-logo>div:first-child>img:first-child {
        width: 134px;
    }
    .ct-logo>div:first-child {
        align-self: flex-end;
        margin-right: 6px;
    }
    .ct-logo>div:nth-child(2) {
        align-self: flex-end;
        margin-right: 6px;
    }
    .ct-logo>div:nth-child(3) {
        display: flex;
        flex-direction: column;
    }
    .ct-logo .logo {
        width: 48px;
        margin-left: 24px;
    }
    .ct-logo .logo-quyen {
        width: 234px;
    }
    .ct-logo .firstP-foo {
        margin-left: 0;
    }
    .ct-intro-footer>img {
        width: 118px;
    }
    .myfix-padding-mobile {
        padding-top: 0;
    }
    .my-imgMenu {
        width: 164px;
        height: 243px;
    }
    .nav-tabs {
        overflow-x: auto;
        max-width: 100%;
        flex-wrap: nowrap;
        overflow-y: hidden;
    }
    .nav-tabs::-webkit-scrollbar{
        width: 0 !important;
        display: none;
    }
    .nav-item {
        flex-shrink: 0;
        padding: 11px 0;
    }
    .scroll-mobile {
        overflow-x: auto;
        max-width: 100%;
    }
    .scroll-mobile::-webkit-scrollbar{
        width: 0 !important;
        display: none;
    }
    .myText-carousel {
        top: 40%;
        left: 5%;
        z-index: 2;
    }
    .history {
        font: normal normal normal 30px/45px Montserrat-Regular;
    }
    .text {
        font: normal normal normal 12px/25px Montserrat-Regular;
        margin-top: unset;
    }
    .imgZoom {
        height: 100vh;
    }
    .slide-pc-footer
    {
        display: none;
    }
}

@media (min-width: 701px) and (max-width: 1180px) {
    .fisrtimgs img:nth-child(4) {
        padding-bottom: 30px;
    }
}

@media (min-width: 756px) and (max-width: 993px) {
    .infor-contact {
        padding-left: 50%;
        padding-right: 50%;
    }
    .shadow-contact {
        width: 100%;
    }
    .like-facebook {
        position: absolute;
    }
    .icon-ytb-zalo {
        flex-direction: row;
        width: 180px;
    }
    .contact-address {
        top: 15%;
        left: 11%;
        width: 30%;
        position: absolute;
    }
   .logo-footer {
        width: 70px;
        height: 90px;
    }
    .shop2 {
        top: 15%;
        left: 41%;
        position: absolute;
        width: 24%;
    }
    /*News*/
    /* .img-carousel {
        width: 30%;
        height: 100%;
    } */
    
    .slideFooter {
        overflow-x: auto;
        overflow-y: hidden;
    }
}
@media (max-width: 375px) {
    .news-re-mobile pre {
        letter-spacing: 0.5px;
        font-size: 11px !important;
    }
}