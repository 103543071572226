@font-face {
  font-family: "UTMAlterGothic";
  src: local("UTMAlterGothic"),
    url("./fonts/UTMAlterGothic.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url("./fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"),
    url("./fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url("./fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "SFUFuturaBold";
  src: local("SFUFuturaBold"),
    url("./fonts/SFUFuturaBold.TTF") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "SFUFuturaBook";
  src: local("SFUFuturaBook"),
    url("./fonts/SFUFuturaBook.TTF") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Montserrat-BoldItalic";
  src: local("Montserrat-BoldItalic"),
    url("./fonts/Montserrat-BoldItalic.ttf") format("truetype");
  font-weight: normal;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFAF0 0% 0% no-repeat padding-box;
}

#root{
  overflow: hidden;
}
#root::-webkit-scrollbar
{
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a:hover{
  color: unset;
}
.DathangPC
{
    position: fixed;
    z-index: 10000;
    bottom: 50px;
    right: 50px;
    
}
.Dathang
{
    position: fixed;
    right:0;
    z-index: 10000;
    bottom:2px
}
.Dathangphone
{
    position: fixed;
    z-index: 10000;
    bottom: 0;
    right: 0;
    left:0
    
}
html {
  scroll-behavior: smooth;
}
/* body::after{
  content: "";
  position: fixed;
  z-index: 9999;
  background-color: black;
  opacity: .6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
} */