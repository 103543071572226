.card-text-name
{
    text-align: left;
    font: normal normal normal 18px/25px UTM Alter Gothic;
    letter-spacing: 0px;
    color: #840C16;
    opacity: 1;
    padding-top: 10px;
    height: 90px;
    
}
.card-text-date
{
    text-align: left;
    font: normal normal normal 10px/20px Montserrat;
    letter-spacing: 0px;
    color: #FCDE9E;
    opacity: 1;
    margin-bottom: 0px;
    
}
.card-text-comment{
    text-align: left;
    font: normal normal normal 12px/20px Montserrat;
    letter-spacing: 0px;
    color: #392A00;
    opacity: 1;
    height: 60px;
    /* line-height: 1.5rem;*/
     /* height: 4.5rem; */
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  
}
.card{

    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000001A;
    border-radius: 5px;
    opacity: 1;
    padding-top: 10px;
    height: 100%;
 
}
.pagination
{
    position: absolute;
    left: 50%;
  
    transform: translateX(-50%);
}
.hjFLZP
{
    top:50% !important;
    height:0
}
.imageNews {
    height: 301px;
    width: 100%;
    border-radius: 5px;
}
