
.Content{
    background: #FFFAF0 0% 0% no-repeat padding-box;
    width: 100%;
    position: relative;
    margin-bottom: 150px;
    margin-top: -250px;
}
.wraptextBoss{
    width: 405px;
     height: 436px;
     position: absolute;
     top: 180px;
     left: 264px
}
.wrap-container{
    max-width: 1600px;
    margin: auto;
    position: relative;
}
.ct-logo{
    position: absolute;
    height: 482px;
    width: 780px;
    top: -375px;
    right: 77px;
}
.ct-logo .logo{
    margin-left: 120px;
}
.ct-logo .logo-quyen{
    margin-top: 13px;
}
.ct-logo .contenthv{
    text-align: center;
    width: 351px;
    height: 63px;
    background: #FFBE00 0% 0% no-repeat padding-box;
    border-radius: 10px;
    margin: auto;
}
.ct-logo .logo-huongvi{
    position: relative;
    margin-left: 256px;
    width: 371px;
    height: 83px;
    display: flex;
}
.ct-logo .contenthv > span{
    font: normal normal normal 53px/65px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840C16;
}
.ct-logo .text-title{
    margin-top: 25px;
    display: block;
    text-align: left;
    font: normal normal normal 18px/30px Montserrat-Regular;
    letter-spacing: 0px;
    color: #392A00;
}
.ct-logo .firstP-foot{
    display: block;
    margin-top: 30px;
    text-indent: 40px;
    font: normal normal bold 30px/37px Montserrat-Bold;
    letter-spacing: 0px;
    color: #840C16;
    width: 480px;
    height: 74px;
    margin-left: auto;
}
.ct-boss{
    top: 0;
    /* left: 131px;
    position: absolute; */
    padding-top: 180px;
    height: 1000px;
    text-align: center;
    width: 1370px;
}
.ct-boss .textboss1{
    display: block;
    width: 252px;
    text-align: left;
    font: normal normal normal 18px/30px Montserrat-Regular;
    letter-spacing: 0px;
    color: #392A00;
    margin-bottom: 35px;
}
.ct-boss .textboss2{
    display: block;
    width: 441px;
    text-align: left;
    font: normal normal normal 18px/30px Montserrat-Regular;
    letter-spacing: 0px;
    color: #392A00;
}
.ct-boss .imgboss{
    position: relative;
    left: 116px;
     width: 929px;
     height: 1065px;
}
@media (max-width: 1333px) {
    .ct-boss .imgboss{
        left: 0;
    }
}
.ct-boss .nameboss{
    display: block;
    font-size: 25px;
    /* font: normal normal bold 25px/31px Muli; */
    letter-spacing: 3.75px;
    color: #FFFFFF;
    text-align: left;
}
.ct-boss .titleboss{
    display: block;
    /* font: normal normal normal 20px/25px Muli; */
    font-size: 20px;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 0.57;
    text-align: right;
}
.ct-boss .bossbr01{
    top: 52px;
    left: 0;
    position: absolute;
    z-index: 1;

}
.ct-boss .bossbr02{
    top: 52px;
    left: 0;
    position: absolute;
    z-index: 0;
    
}
.ct-boss .bossshape{
    top: -111px;
    left: 0;
    position: absolute;
    z-index: 2;
}
.ct-boss .boss1946{
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 3;
    text-align: left;
    font: normal normal normal 366px/457px UTMAlterGothic;
    letter-spacing: -18.3px;
    color: #840C16;
    text-shadow: -50px -50px 0px #00000014;
    opacity: 0.89;
}

.ct-boss .textboss{
    width: 319px;
    position: absolute;
    top: 612px;
    right: 184px;
    z-index: 3;
}
.wrap-boss{
    max-width: 1423px;
    position: relative;
    margin: 0px auto;
    height: 1245px;
    overflow: hidden;
    margin-bottom: 70px;
}
.textboxboss{
    background: #FCDE9E 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 0.82;
    height: 400px;
    width: 376px;
    position: absolute;
    top: 18px;
    left: 15px;
    text-align: left;
    font: normal normal normal 35px/44px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840C16;
}
.ct-intro-head > span{
     width: 1143px;
    height: 145px;
}
.ct-boss .borderboxtop{
    position: absolute;
     top: 0;
     left: 0;
     width: 57px;
     height: 46px;
     border-top: 5px solid #840C16;
     border-left: 5px solid #840C16;
    border-top-left-radius: 5px 5px;
}
.ct-boss .borderboxbottom{
    position: absolute;
     bottom: 0;
     right: 0;
     width: 57px;
     height: 46px;
     border-bottom: 5px solid #840C16;
     border-right: 5px solid #840C16;
    border-bottom-right-radius: 5px 5px;
}
.ct-intro-body .col5-custom{
    align-items: flex-end;
    padding: 0;
    margin: 0;
}
.ct-multiRow{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.ct-multiRow .fisrtimgs{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.ct-multiRow .secondimgs{
    display: flex;
    justify-content: space-between;
}
.ct-intro-head{
    margin-top: 67px;
    text-align: left;
    font: normal normal normal 18px/30px Montserrat-Regular;
    letter-spacing: 0px;
    color: #392A00;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ct-intro-head .upper{
    text-align: left;
    font: normal normal 600 18px/30px Montserrat-SemiBold;
    letter-spacing: 0px;
    color: #840C16;
}
.ct-intro-head .huongvidicung{
    position: absolute;
    top: 30px;
    left: 23px;
    display: block;
    width: 420px;
    height: 74px;
    text-align: left;
    text-indent: 20px;
    font: normal normal bold 30px/37px Montserrat-Bold;
    letter-spacing: 0px;
    color: #840C16;
}
.box1hv{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 41px;
    height: 33px;
    border-top: 3px solid rgb(252, 222, 158);
    border-left: 3px solid rgb(252, 222, 158);
}
.myfix-padding-mobile{
    padding: 25px;
}
.box2hv{
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 41px;
    height: 33px;
    border-bottom: 3px solid rgb(252, 222, 158);
    border-right: 3px solid rgb(252, 222, 158);
}
.textboxboss > span{
    display: block;
    width: 341px;
    height: 371px;
    padding-top: 23px;
    padding-left: 32px;
}
.ct-intro-body{
    margin-top: 74px;
}
.ct-intro-body .textrow1{
    
    min-width: 260px;
}
span{
    display: block;
}
.textrow1 > span ,.textrow2 > span{
    display: block;
}
.ct-intro-body .col-nth3{
    padding: 0;
    margin: 0;
    text-align: end;
    justify-content: end;
}
.body-row1 > div:nth-child(4){
    padding: 0;
    margin: 0;
    align-self: flex-start;
}   
.col-nth3 > div:first-child{
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}
.col-nth3-div{
    padding-top: 14px;
}
.col-nth3-1{
   position: relative;
    left: 18px;
}
.myfiximg-01{
    /* align-self: flex-end; */
}
.myfiximg-01 > img{
   width: 85.7%;
}
.footer-custom{
    background-color: #ffb300;
}
.textrow1-head{
    text-align: left;
    letter-spacing: 0px;
    color: #392A00;
    font: normal normal normal 15px/30px Montserrat-Regular;
}
.textrow1-body{
    text-align: left;
    font: normal normal normal 40px/50px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840C16;
}
.textrow1-foot{
    margin-top: 13px;
    text-align: left;
    font: normal normal normal 15px/20px Montserrat-Regular;
    letter-spacing: 0px;
    color: #392A00;
}
.ct-intro-footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 97px;
}
.ct-intro-footer > img{
    margin-bottom: 39px;
}
.footer-row1{
    width: 1057px;
    text-align: center;
    font: normal normal 30px/37px Montserrat-Bold;
    font-weight: 900;
    letter-spacing: 0px;
    color: #840C16;
}
.text-title span{
    display: inline;
}
.footer-row1 span{
    display: inline;
    font: normal normal bold 40px/37px Montserrat-Bold;
    color: #FFBE00;
}
.footer-row2{
    display: block;
    text-align: center;
    font: normal normal normal 13px/20px Montserrat-Regular;
    letter-spacing: 0px;
    color: #392A00;
}
.footer-row2{
    width: 733px;
    margin-top: 16px;
}
.mycarouselimg{
    border-radius: 5px;
    width: 817px;
    height: 105px;
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
}
#carouselimg{
    width: 100%;
    height: 100%;
    position: relative;
}
.myinnerimg{
    position: absolute;
    top: 15px;
    width: unset;
    left: 10px;
    height: 77px;
}
.pre-btncarimg{
    position: absolute;
    left: -18px;
    width: 0;
}
.next-btncarimg{
    position: absolute;
    right: -18px;
    width: 0;
}
.slide-box > img{
    padding: 0 4px;
}
.ct-intro-body .body-row1{
    align-items: flex-end;
}
.body-row2{
    
}
.wrap-textrow1{
    /* margin-left: 26px;
    margin-bottom: 26px; */
    padding: 25px;
}
.textrow1-head .custom-text{
    font: normal normal 600 15px/30px Montserrat-SemiBold;
    display: inline-block;
}
.ct-intro-head span{
    display: inline;
}
@media (max-width: 1200px) {
    .wrap-textrow1{
        width: 69%;
    }
    .textrow1-head, .textrow1-body, .textrow1-foot, .textrow1-head .custom-text{
        font-size: 13px;
    }
    .mycarouselimg{
        width: 645px;
    }
    .myinnerimg{
        left: 6px;
    }
    .textrow1-body{
        font-size: 30px;
        line-height: 40px;
    }
    
}
@media  (min-width :1200px) and (max-width:1650px) {
    .ct-intro-body
    {
        width:100%;
        padding-left:10px;
        padding-right: 10px;
    }
}
@media (min-width:1650px) {
    .ct-intro-body
    {
        width:100%;
    }
}