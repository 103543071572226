@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;1,600&display=swap);
@font-face {
  font-family: "UTMAlterGothic";
  src: local("UTMAlterGothic"),
    url(/static/media/UTMAlterGothic.439c1b95.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url(/static/media/Montserrat-Regular.2a2d37bf.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"),
    url(/static/media/Montserrat-SemiBold.92113135.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url(/static/media/Montserrat-Bold.0915f0a8.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "SFUFuturaBold";
  src: local("SFUFuturaBold"),
    url(/static/media/SFUFuturaBold.ad152948.TTF) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "SFUFuturaBook";
  src: local("SFUFuturaBook"),
    url(/static/media/SFUFuturaBook.60e333d7.TTF) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Montserrat-BoldItalic";
  src: local("Montserrat-BoldItalic"),
    url(/static/media/Montserrat-BoldItalic.04263045.ttf) format("truetype");
  font-weight: normal;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFAF0 0% 0% no-repeat padding-box;
}

#root{
  overflow: hidden;
}
#root::-webkit-scrollbar
{
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a:hover{
  color: unset;
}
.DathangPC
{
    position: fixed;
    z-index: 10000;
    bottom: 50px;
    right: 50px;
    
}
.Dathang
{
    position: fixed;
    right:0;
    z-index: 10000;
    bottom:2px
}
.Dathangphone
{
    position: fixed;
    z-index: 10000;
    bottom: 0;
    right: 0;
    left:0
    
}
html {
  scroll-behavior: smooth;
}
/* body::after{
  content: "";
  position: fixed;
  z-index: 9999;
  background-color: black;
  opacity: .6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
} */
.Header
{
    position: fixed;
    width: 100%;
    text-align: left;
    font: normal normal normal 16px/21px SFUFuturaBook;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    z-index:2000;
    
    align-items: center ;
    
    
}
.Header.slidedow{
    position: fixed;
    /* top: 0;
    left: 0;
    right: 0; */
    background-color: #EBEDEB;
    margin-left: 0;
    align-items: center ;
    opacity: 0.8;
    padding-right: 5px;
}
.nav-link.show
{
    color: black;
}
.nav-link
{
    color: white;
}
.nen{
    position: relative;
    background-image: url(/static/media/nen.1afea85d.webp);
    width: 100%;
    height: calc(100vh + 100px);
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed; */
    background-position: center;
}
.nen01{
    position: relative;
    background-image: url(/static/media/nen01.f0a47835.webp);
    width: 100%;
    height: calc(100vh + 100px);
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed; */
    background-position: center;
}
@media (min-width :756px) and (max-width:1650px) {
    .Nav-Phone
    {
        display: none;
    }
    .icon
    {
        display: none;
    }
  }
@media (min-width:1650px) {
    .Nav-Phone
    {
        display: none;
    }
    .icon
    {
        display: none;
    }
}
.color
{
    position: absolute;
    background: #000000 0% 0% no-repeat padding-box;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity:0.55;
    z-index: 1;
}
.color-no-touch{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity:0.55;
    z-index: 1;
}
.myText-carousel{
    position: absolute;
     top: 40vh;
     left: 10%;
     z-index: 2;
}
@-webkit-keyframes textUpBCQ{
    0%{
        opacity: 0;
        -webkit-transform: translateY(30px);
                transform: translateY(30px);
    }
    100%{
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
@keyframes textUpBCQ{
    0%{
        opacity: 0;
        -webkit-transform: translateY(30px);
                transform: translateY(30px);
    }
    100%{
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
.Nav-Home
{
    position: sticky;
    z-index:1000;
    font: normal normal normal 16px/21px SFUFuturaBook;
    color: #FFFFFF;
    opacity: 1;
    font-size: 20px;
    
    
}
#demo
{
    
}
.history{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 33px/50px var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal normal 33px/50px Montserrat-Regular;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    z-index: 1000;
    -webkit-animation: textUpBCQ 1s ease-in;
            animation: textUpBCQ 1s ease-in
}
.text
{
    margin-top: 16px;
    text-align: left;
    font: normal normal normal 15px/30px Montserrat-Regular;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    z-index: 100;
    -webkit-animation: textUpBCQ 1.2s .2s ease-in-out;
            animation: textUpBCQ 1.2s .2s ease-in-out;
}
/* .car-header{
    po
} */
.imgZoom{
    width: 100%;
    -webkit-animation: zoomIn 6s ease-out;
            animation: zoomIn 6s ease-out;
   
}
@-webkit-keyframes zoomIn {
    0%{
          -webkit-transform: scale(1,1);
                  transform: scale(1,1);
          opacity: .8;
    }
    100%{
        -webkit-transform: scale(1.1,1.1);
                transform: scale(1.1,1.1);
        opacity: 1;
    }
    
}
@keyframes zoomIn {
    0%{
          -webkit-transform: scale(1,1);
                  transform: scale(1,1);
          opacity: .8;
    }
    100%{
        -webkit-transform: scale(1.1,1.1);
                transform: scale(1.1,1.1);
        opacity: 1;
    }
    
}
@media (max-width:755px) {
    .Nav-Home
    {
        display: none;
    }
}
.logoHeader
{
    width:128px;
   
}

/*Màn hính cỡ nhỏ*/

.Menu {
    position: relative;
    background-repeat: no-repeat;
    opacity: 1;
}

.my-imgMenu {
    width: 215px;
    height: 316px;
}
.notesImg {
    z-index: 2;
    position: absolute;
    width: 21%;
    height: 15%;
    top: 31%;
    left: 2.5%;
}

@media (min-width :1100px) and (max-width:1649px) {
    .Gioithieu {
        background-image: url(/static/media/nen2.57949f6b.png);
        width: 100%;
        z-index: -1;
        height: 1067px;
        opacity: 0.36;
        position: absolute;
        background-attachment: fixed;
        background-position: center;
    }
    .GT {
        position: relative;
        width: 100%;
        height: 1067px;
    }
    .maket {
        background: 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
        left: 250px;
        width: 418px;
        height: 275px;
        z-index: 1;
        position: absolute;
        top: -130px;
    }
    .my-imgMenu {
        width: 215px;
    }
    .anhtet {
        z-index: 2;
        position: absolute;
        top: -130px;
        left: 670px;
        width: 650px;
        height: 463px;
        background: 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
    }
    .Street {
        position: absolute;
        background: 0% 0% no-repeat padding-box;
        box-shadow: -5px 5px 0px #00000012;
        border-radius: 10px;
        opacity: 1;
        margin-left: 200px;
        width: 272px;
        height: 421px;
        margin-top: 280px
    }
    .test {
        margin-bottom: -100px;
        margin-left: 625px;
    }
    /* .banhcuon
    {
        width: 200px;
        height: 30px;
        opacity: 1;
        margin-top: -1100px;
    } */
    .Menu {
        height: 800px;
    }
    /* .Trayproduct
    {
        position: absolute;
        margin-left: 185px;
        top:40px;
        right: 662px;
        width: 1066px;
        height: 655px;
       
        opacity: 1;
    } */
    /* .Logo{
        position: absolute;
        top:50px;
        left: 600px;
        width: 390px;
        height: 390px;
        opacity: 1;
    } */
    .Banhcuon {
        position: relative;
        top: 370px;
        left: 310px;
        width: 27px;
        height: 27px;
        opacity: 1;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAABHNCSVQICAgIfAhkiAAAAnNJREFUSEu9Vj1MU1EU/m5bEpvUqDFC3SwObDho4or8bK1x9WcTcasYHR06+LNAFLYWXGVgIZSNFllNYJDNQepmK4OY16QmwHue8/58fe/evgfKu8kd2nfu+e53vnPPOQIhy/iIDI4wAmHuDJkP0T5LW6P9BQa+095GEpviFtq93AnVRxtkCgncC7uQ+13HBwKtqEClYEYNeQJ5ZjOIjGUbatAxK8ax5j8YADM2MEVGvP9tJVAWI1jwOukC+29AfxEqYhQV56cLZoeuJKPTIdl36mn82E2i9S2F5m4fsoMHGLhyiP7BIwyPdZDm1JEtHSUnpCaYmQwGqjKNml9TWH5zDr9aCWVYGThfbCN79VBmo1EWFzhpLLAaJUMCd/2WO+tnsDrPWR5t3S5qGJ74HTTWsUTsZoXNatNvwYwWpy9EQ/FYTb77KWdI71TItGKNFqcv9gyd6hYc0vuv9oMasnZGHTNmdfCsTytprL9XKR5OduJhGzfvdLoNDawJSvcy/Xvd+6X6NoPPG+lwrwqLa6MdFJ4GKtc2g235zywUz6PV6DsxGIdycm7ff16Tgr0sXDoxkHPwRXUv4ONUmA3kDvBo3sfMoHcWr2Z1lCgb8/Fko9VOSl4w850V6Z3tqUuUSlQO4YPXqndm1cV4KgjfMLbaaIIxO506qzVjdC2ukdW5TM93x6ErPFFUfcpCkinvVn2bXUA7B9XsZzXqZ40kmo2UCcwA2Rz1sxz1s/Fj9DPHaWyd+hQAu0YC9q+ergSeyzQMrWOskYGZSNOVy9AaTh/LOrgSkDoyzY3lY82NXmeeifgGMb1Mtx4yGTMD4U7EW1Em4j/BwQpyJpM7iAAAAABJRU5ErkJggg==);
        cursor: pointer;
    }
    .Cha {
        position: relative;
        top: 200px;
        left: 470px;
        width: 27px;
        height: 27px;
        opacity: 1;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAABHNCSVQICAgIfAhkiAAAAnNJREFUSEu9Vj1MU1EU/m5bEpvUqDFC3SwObDho4or8bK1x9WcTcasYHR06+LNAFLYWXGVgIZSNFllNYJDNQepmK4OY16QmwHue8/58fe/evgfKu8kd2nfu+e53vnPPOQIhy/iIDI4wAmHuDJkP0T5LW6P9BQa+095GEpviFtq93AnVRxtkCgncC7uQ+13HBwKtqEClYEYNeQJ5ZjOIjGUbatAxK8ax5j8YADM2MEVGvP9tJVAWI1jwOukC+29AfxEqYhQV56cLZoeuJKPTIdl36mn82E2i9S2F5m4fsoMHGLhyiP7BIwyPdZDm1JEtHSUnpCaYmQwGqjKNml9TWH5zDr9aCWVYGThfbCN79VBmo1EWFzhpLLAaJUMCd/2WO+tnsDrPWR5t3S5qGJ74HTTWsUTsZoXNatNvwYwWpy9EQ/FYTb77KWdI71TItGKNFqcv9gyd6hYc0vuv9oMasnZGHTNmdfCsTytprL9XKR5OduJhGzfvdLoNDawJSvcy/Xvd+6X6NoPPG+lwrwqLa6MdFJ4GKtc2g235zywUz6PV6DsxGIdycm7ff16Tgr0sXDoxkHPwRXUv4ONUmA3kDvBo3sfMoHcWr2Z1lCgb8/Fko9VOSl4w850V6Z3tqUuUSlQO4YPXqndm1cV4KgjfMLbaaIIxO506qzVjdC2ukdW5TM93x6ErPFFUfcpCkinvVn2bXUA7B9XsZzXqZ40kmo2UCcwA2Rz1sxz1s/Fj9DPHaWyd+hQAu0YC9q+ergSeyzQMrWOskYGZSNOVy9AaTh/LOrgSkDoyzY3lY82NXmeeifgGMb1Mtx4yGTMD4U7EW1Em4j/BwQpyJpM7iAAAAABJRU5ErkJggg==);
        cursor: pointer;
    }
    .Nuocham {
        position: relative;
        top: 450px;
        left: 580px;
        width: 27px;
        height: 27px;
        opacity: 1;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAABHNCSVQICAgIfAhkiAAAAnNJREFUSEu9Vj1MU1EU/m5bEpvUqDFC3SwObDho4or8bK1x9WcTcasYHR06+LNAFLYWXGVgIZSNFllNYJDNQepmK4OY16QmwHue8/58fe/evgfKu8kd2nfu+e53vnPPOQIhy/iIDI4wAmHuDJkP0T5LW6P9BQa+095GEpviFtq93AnVRxtkCgncC7uQ+13HBwKtqEClYEYNeQJ5ZjOIjGUbatAxK8ax5j8YADM2MEVGvP9tJVAWI1jwOukC+29AfxEqYhQV56cLZoeuJKPTIdl36mn82E2i9S2F5m4fsoMHGLhyiP7BIwyPdZDm1JEtHSUnpCaYmQwGqjKNml9TWH5zDr9aCWVYGThfbCN79VBmo1EWFzhpLLAaJUMCd/2WO+tnsDrPWR5t3S5qGJ74HTTWsUTsZoXNatNvwYwWpy9EQ/FYTb77KWdI71TItGKNFqcv9gyd6hYc0vuv9oMasnZGHTNmdfCsTytprL9XKR5OduJhGzfvdLoNDawJSvcy/Xvd+6X6NoPPG+lwrwqLa6MdFJ4GKtc2g235zywUz6PV6DsxGIdycm7ff16Tgr0sXDoxkHPwRXUv4ONUmA3kDvBo3sfMoHcWr2Z1lCgb8/Fko9VOSl4w850V6Z3tqUuUSlQO4YPXqndm1cV4KgjfMLbaaIIxO506qzVjdC2ukdW5TM93x6ErPFFUfcpCkinvVn2bXUA7B9XsZzXqZ40kmo2UCcwA2Rz1sxz1s/Fj9DPHaWyd+hQAu0YC9q+ergSeyzQMrWOskYGZSNOVy9AaTh/LOrgSkDoyzY3lY82NXmeeifgGMb1Mtx4yGTMD4U7EW1Em4j/BwQpyJpM7iAAAAABJRU5ErkJggg==);
        cursor: pointer;
    }
    .Hanh {
        position: relative;
        top: 490px;
        left: 630px;
        width: 27px;
        height: 27px;
        opacity: 1;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAABHNCSVQICAgIfAhkiAAAAnNJREFUSEu9Vj1MU1EU/m5bEpvUqDFC3SwObDho4or8bK1x9WcTcasYHR06+LNAFLYWXGVgIZSNFllNYJDNQepmK4OY16QmwHue8/58fe/evgfKu8kd2nfu+e53vnPPOQIhy/iIDI4wAmHuDJkP0T5LW6P9BQa+095GEpviFtq93AnVRxtkCgncC7uQ+13HBwKtqEClYEYNeQJ5ZjOIjGUbatAxK8ax5j8YADM2MEVGvP9tJVAWI1jwOukC+29AfxEqYhQV56cLZoeuJKPTIdl36mn82E2i9S2F5m4fsoMHGLhyiP7BIwyPdZDm1JEtHSUnpCaYmQwGqjKNml9TWH5zDr9aCWVYGThfbCN79VBmo1EWFzhpLLAaJUMCd/2WO+tnsDrPWR5t3S5qGJ74HTTWsUTsZoXNatNvwYwWpy9EQ/FYTb77KWdI71TItGKNFqcv9gyd6hYc0vuv9oMasnZGHTNmdfCsTytprL9XKR5OduJhGzfvdLoNDawJSvcy/Xvd+6X6NoPPG+lwrwqLa6MdFJ4GKtc2g235zywUz6PV6DsxGIdycm7ff16Tgr0sXDoxkHPwRXUv4ONUmA3kDvBo3sfMoHcWr2Z1lCgb8/Fko9VOSl4w850V6Z3tqUuUSlQO4YPXqndm1cV4KgjfMLbaaIIxO506qzVjdC2ukdW5TM93x6ErPFFUfcpCkinvVn2bXUA7B9XsZzXqZ40kmo2UCcwA2Rz1sxz1s/Fj9DPHaWyd+hQAu0YC9q+ergSeyzQMrWOskYGZSNOVy9AaTh/LOrgSkDoyzY3lY82NXmeeifgGMb1Mtx4yGTMD4U7EW1Em4j/BwQpyJpM7iAAAAABJRU5ErkJggg==);
        cursor: pointer;
    }
    .Rau {
        position: relative;
        top: 130px;
        left: 650px;
        width: 27px;
        height: 27px;
        opacity: 1;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAABHNCSVQICAgIfAhkiAAAAnNJREFUSEu9Vj1MU1EU/m5bEpvUqDFC3SwObDho4or8bK1x9WcTcasYHR06+LNAFLYWXGVgIZSNFllNYJDNQepmK4OY16QmwHue8/58fe/evgfKu8kd2nfu+e53vnPPOQIhy/iIDI4wAmHuDJkP0T5LW6P9BQa+095GEpviFtq93AnVRxtkCgncC7uQ+13HBwKtqEClYEYNeQJ5ZjOIjGUbatAxK8ax5j8YADM2MEVGvP9tJVAWI1jwOukC+29AfxEqYhQV56cLZoeuJKPTIdl36mn82E2i9S2F5m4fsoMHGLhyiP7BIwyPdZDm1JEtHSUnpCaYmQwGqjKNml9TWH5zDr9aCWVYGThfbCN79VBmo1EWFzhpLLAaJUMCd/2WO+tnsDrPWR5t3S5qGJ74HTTWsUTsZoXNatNvwYwWpy9EQ/FYTb77KWdI71TItGKNFqcv9gyd6hYc0vuv9oMasnZGHTNmdfCsTytprL9XKR5OduJhGzfvdLoNDawJSvcy/Xvd+6X6NoPPG+lwrwqLa6MdFJ4GKtc2g235zywUz6PV6DsxGIdycm7ff16Tgr0sXDoxkHPwRXUv4ONUmA3kDvBo3sfMoHcWr2Z1lCgb8/Fko9VOSl4w850V6Z3tqUuUSlQO4YPXqndm1cV4KgjfMLbaaIIxO506qzVjdC2ukdW5TM93x6ErPFFUfcpCkinvVn2bXUA7B9XsZzXqZ40kmo2UCcwA2Rz1sxz1s/Fj9DPHaWyd+hQAu0YC9q+ergSeyzQMrWOskYGZSNOVy9AaTh/LOrgSkDoyzY3lY82NXmeeifgGMb1Mtx4yGTMD4U7EW1Em4j/BwQpyJpM7iAAAAABJRU5ErkJggg==);
        cursor: pointer;
    }
    .Nav_Tab {
        position: relative;
    }
    .nav-item {
        cursor: pointer;
    }
    .KhungHinh2 {
        border-bottom: 3px solid #FFBE00;
        border-right: 3px solid #FFBE00;
        /* border-bottom-right-radius: 3px 3px; */
        margin-right: -10px;
        margin-bottom: 3px
    }
    .Textphone {
        display: none;
    }
    .notesImg-mb {
        top: 25%;
        left: 2.5%;
    }
}


/*Màn hính cỡ to*/

@media (min-width: 1650px) {
    .Gioithieu {
        background-image: url(/static/media/Nen.d0acc695.webp);
        width: 100%;
        z-index: -1;
        height: 1067px;
        opacity: 0.36;
        position: absolute;
    }
    .GT {
        position: relative;
        width: 100%;
        height: 1067px;
    }
    .Menu {
        /* height: 900px; */
        margin-bottom: 700px;
    }
    .maket {
        background: 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
        left: 550px;
        width: 418px;
        height: 275px;
        z-index: 1;
        position: absolute;
        top: -130px;
    }
    .anhtet {
        z-index: 2;
        position: absolute;
        top: -130px;
        left: 1000px;
        width: 650px;
        height: 463px;
        background: 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
    }
    .Street {
        position: absolute;
        background: 0% 0% no-repeat padding-box;
        box-shadow: -5px 5px 0px #00000012;
        border-radius: 10px;
        opacity: 1;
        margin-left: 350px;
        width: 272px;
        height: 421px;
        margin-top: 280px
    }
    .test {
        margin-bottom: -200px;
        margin-left: 700px;
    }
    .banhcuon {
        width: 250px;
        height: 38px;
        opacity: 1;
        margin-top: -1200px;
    }
    .Banhcuon {
        position: relative;
        top: 400px;
        /* right: 20px; */
        left: 270px;
        width: 27px;
        height: 27px;
        opacity: 1;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAABHNCSVQICAgIfAhkiAAAAnNJREFUSEu9Vj1MU1EU/m5bEpvUqDFC3SwObDho4or8bK1x9WcTcasYHR06+LNAFLYWXGVgIZSNFllNYJDNQepmK4OY16QmwHue8/58fe/evgfKu8kd2nfu+e53vnPPOQIhy/iIDI4wAmHuDJkP0T5LW6P9BQa+095GEpviFtq93AnVRxtkCgncC7uQ+13HBwKtqEClYEYNeQJ5ZjOIjGUbatAxK8ax5j8YADM2MEVGvP9tJVAWI1jwOukC+29AfxEqYhQV56cLZoeuJKPTIdl36mn82E2i9S2F5m4fsoMHGLhyiP7BIwyPdZDm1JEtHSUnpCaYmQwGqjKNml9TWH5zDr9aCWVYGThfbCN79VBmo1EWFzhpLLAaJUMCd/2WO+tnsDrPWR5t3S5qGJ74HTTWsUTsZoXNatNvwYwWpy9EQ/FYTb77KWdI71TItGKNFqcv9gyd6hYc0vuv9oMasnZGHTNmdfCsTytprL9XKR5OduJhGzfvdLoNDawJSvcy/Xvd+6X6NoPPG+lwrwqLa6MdFJ4GKtc2g235zywUz6PV6DsxGIdycm7ff16Tgr0sXDoxkHPwRXUv4ONUmA3kDvBo3sfMoHcWr2Z1lCgb8/Fko9VOSl4w850V6Z3tqUuUSlQO4YPXqndm1cV4KgjfMLbaaIIxO506qzVjdC2ukdW5TM93x6ErPFFUfcpCkinvVn2bXUA7B9XsZzXqZ40kmo2UCcwA2Rz1sxz1s/Fj9DPHaWyd+hQAu0YC9q+ergSeyzQMrWOskYGZSNOVy9AaTh/LOrgSkDoyzY3lY82NXmeeifgGMb1Mtx4yGTMD4U7EW1Em4j/BwQpyJpM7iAAAAABJRU5ErkJggg==);
        cursor: pointer;
    }
    .Cha {
        position: relative;
        top: 200px;
        left: 500px;
        width: 27px;
        height: 27px;
        opacity: 1;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAABHNCSVQICAgIfAhkiAAAAnNJREFUSEu9Vj1MU1EU/m5bEpvUqDFC3SwObDho4or8bK1x9WcTcasYHR06+LNAFLYWXGVgIZSNFllNYJDNQepmK4OY16QmwHue8/58fe/evgfKu8kd2nfu+e53vnPPOQIhy/iIDI4wAmHuDJkP0T5LW6P9BQa+095GEpviFtq93AnVRxtkCgncC7uQ+13HBwKtqEClYEYNeQJ5ZjOIjGUbatAxK8ax5j8YADM2MEVGvP9tJVAWI1jwOukC+29AfxEqYhQV56cLZoeuJKPTIdl36mn82E2i9S2F5m4fsoMHGLhyiP7BIwyPdZDm1JEtHSUnpCaYmQwGqjKNml9TWH5zDr9aCWVYGThfbCN79VBmo1EWFzhpLLAaJUMCd/2WO+tnsDrPWR5t3S5qGJ74HTTWsUTsZoXNatNvwYwWpy9EQ/FYTb77KWdI71TItGKNFqcv9gyd6hYc0vuv9oMasnZGHTNmdfCsTytprL9XKR5OduJhGzfvdLoNDawJSvcy/Xvd+6X6NoPPG+lwrwqLa6MdFJ4GKtc2g235zywUz6PV6DsxGIdycm7ff16Tgr0sXDoxkHPwRXUv4ONUmA3kDvBo3sfMoHcWr2Z1lCgb8/Fko9VOSl4w850V6Z3tqUuUSlQO4YPXqndm1cV4KgjfMLbaaIIxO506qzVjdC2ukdW5TM93x6ErPFFUfcpCkinvVn2bXUA7B9XsZzXqZ40kmo2UCcwA2Rz1sxz1s/Fj9DPHaWyd+hQAu0YC9q+ergSeyzQMrWOskYGZSNOVy9AaTh/LOrgSkDoyzY3lY82NXmeeifgGMb1Mtx4yGTMD4U7EW1Em4j/BwQpyJpM7iAAAAABJRU5ErkJggg==);
        cursor: pointer;
    }
    .Nuocham {
        position: relative;
        top: 450px;
        left: 580px;
        width: 27px;
        height: 27px;
        opacity: 1;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAABHNCSVQICAgIfAhkiAAAAnNJREFUSEu9Vj1MU1EU/m5bEpvUqDFC3SwObDho4or8bK1x9WcTcasYHR06+LNAFLYWXGVgIZSNFllNYJDNQepmK4OY16QmwHue8/58fe/evgfKu8kd2nfu+e53vnPPOQIhy/iIDI4wAmHuDJkP0T5LW6P9BQa+095GEpviFtq93AnVRxtkCgncC7uQ+13HBwKtqEClYEYNeQJ5ZjOIjGUbatAxK8ax5j8YADM2MEVGvP9tJVAWI1jwOukC+29AfxEqYhQV56cLZoeuJKPTIdl36mn82E2i9S2F5m4fsoMHGLhyiP7BIwyPdZDm1JEtHSUnpCaYmQwGqjKNml9TWH5zDr9aCWVYGThfbCN79VBmo1EWFzhpLLAaJUMCd/2WO+tnsDrPWR5t3S5qGJ74HTTWsUTsZoXNatNvwYwWpy9EQ/FYTb77KWdI71TItGKNFqcv9gyd6hYc0vuv9oMasnZGHTNmdfCsTytprL9XKR5OduJhGzfvdLoNDawJSvcy/Xvd+6X6NoPPG+lwrwqLa6MdFJ4GKtc2g235zywUz6PV6DsxGIdycm7ff16Tgr0sXDoxkHPwRXUv4ONUmA3kDvBo3sfMoHcWr2Z1lCgb8/Fko9VOSl4w850V6Z3tqUuUSlQO4YPXqndm1cV4KgjfMLbaaIIxO506qzVjdC2ukdW5TM93x6ErPFFUfcpCkinvVn2bXUA7B9XsZzXqZ40kmo2UCcwA2Rz1sxz1s/Fj9DPHaWyd+hQAu0YC9q+ergSeyzQMrWOskYGZSNOVy9AaTh/LOrgSkDoyzY3lY82NXmeeifgGMb1Mtx4yGTMD4U7EW1Em4j/BwQpyJpM7iAAAAABJRU5ErkJggg==);
        cursor: pointer;
    }
    .Hanh {
        position: relative;
        top: 530px;
        left: 650px;
        width: 27px;
        height: 27px;
        opacity: 1;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAABHNCSVQICAgIfAhkiAAAAnNJREFUSEu9Vj1MU1EU/m5bEpvUqDFC3SwObDho4or8bK1x9WcTcasYHR06+LNAFLYWXGVgIZSNFllNYJDNQepmK4OY16QmwHue8/58fe/evgfKu8kd2nfu+e53vnPPOQIhy/iIDI4wAmHuDJkP0T5LW6P9BQa+095GEpviFtq93AnVRxtkCgncC7uQ+13HBwKtqEClYEYNeQJ5ZjOIjGUbatAxK8ax5j8YADM2MEVGvP9tJVAWI1jwOukC+29AfxEqYhQV56cLZoeuJKPTIdl36mn82E2i9S2F5m4fsoMHGLhyiP7BIwyPdZDm1JEtHSUnpCaYmQwGqjKNml9TWH5zDr9aCWVYGThfbCN79VBmo1EWFzhpLLAaJUMCd/2WO+tnsDrPWR5t3S5qGJ74HTTWsUTsZoXNatNvwYwWpy9EQ/FYTb77KWdI71TItGKNFqcv9gyd6hYc0vuv9oMasnZGHTNmdfCsTytprL9XKR5OduJhGzfvdLoNDawJSvcy/Xvd+6X6NoPPG+lwrwqLa6MdFJ4GKtc2g235zywUz6PV6DsxGIdycm7ff16Tgr0sXDoxkHPwRXUv4ONUmA3kDvBo3sfMoHcWr2Z1lCgb8/Fko9VOSl4w850V6Z3tqUuUSlQO4YPXqndm1cV4KgjfMLbaaIIxO506qzVjdC2ukdW5TM93x6ErPFFUfcpCkinvVn2bXUA7B9XsZzXqZ40kmo2UCcwA2Rz1sxz1s/Fj9DPHaWyd+hQAu0YC9q+ergSeyzQMrWOskYGZSNOVy9AaTh/LOrgSkDoyzY3lY82NXmeeifgGMb1Mtx4yGTMD4U7EW1Em4j/BwQpyJpM7iAAAAABJRU5ErkJggg==);
        cursor: pointer;
    }
    .Rau {
        position: relative;
        top: 150px;
        left: 650px;
        width: 27px;
        height: 27px;
        opacity: 1;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAABHNCSVQICAgIfAhkiAAAAnNJREFUSEu9Vj1MU1EU/m5bEpvUqDFC3SwObDho4or8bK1x9WcTcasYHR06+LNAFLYWXGVgIZSNFllNYJDNQepmK4OY16QmwHue8/58fe/evgfKu8kd2nfu+e53vnPPOQIhy/iIDI4wAmHuDJkP0T5LW6P9BQa+095GEpviFtq93AnVRxtkCgncC7uQ+13HBwKtqEClYEYNeQJ5ZjOIjGUbatAxK8ax5j8YADM2MEVGvP9tJVAWI1jwOukC+29AfxEqYhQV56cLZoeuJKPTIdl36mn82E2i9S2F5m4fsoMHGLhyiP7BIwyPdZDm1JEtHSUnpCaYmQwGqjKNml9TWH5zDr9aCWVYGThfbCN79VBmo1EWFzhpLLAaJUMCd/2WO+tnsDrPWR5t3S5qGJ74HTTWsUTsZoXNatNvwYwWpy9EQ/FYTb77KWdI71TItGKNFqcv9gyd6hYc0vuv9oMasnZGHTNmdfCsTytprL9XKR5OduJhGzfvdLoNDawJSvcy/Xvd+6X6NoPPG+lwrwqLa6MdFJ4GKtc2g235zywUz6PV6DsxGIdycm7ff16Tgr0sXDoxkHPwRXUv4ONUmA3kDvBo3sfMoHcWr2Z1lCgb8/Fko9VOSl4w850V6Z3tqUuUSlQO4YPXqndm1cV4KgjfMLbaaIIxO506qzVjdC2ukdW5TM93x6ErPFFUfcpCkinvVn2bXUA7B9XsZzXqZ40kmo2UCcwA2Rz1sxz1s/Fj9DPHaWyd+hQAu0YC9q+ergSeyzQMrWOskYGZSNOVy9AaTh/LOrgSkDoyzY3lY82NXmeeifgGMb1Mtx4yGTMD4U7EW1Em4j/BwQpyJpM7iAAAAABJRU5ErkJggg==);
        cursor: pointer;
    }
    .Nav_Tab {
        position: relative;
    }
    .nav-item {
        cursor: pointer;
    }
    .KhungHinh2 {
        border-bottom: 3px solid #FFBE00;
        border-right: 3px solid #FFBE00;
        /* border-bottom-right-radius: 3px 3px; */
        margin-right: -10px;
        margin-bottom: 3px
    }
    .Textphone {
        display: none;
    }
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: #FFBE00;
    border-color: #FFCB08;
    text-align: left;
    font: normal normal normal 25px/31px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840C16;
    opacity: 1;
    border-radius: 5px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
    color: var(--unnamed-color-392a00);
    text-align: left;
    font: normal normal normal 25px/31px UTMAlterGothic;
    letter-spacing: 0px;
    color: #392A00;
    opacity: 1;
}

.Tab {
    position: relative;
    left: 0px;
    width: 100%;
    height: auto;
    background: #EFE5D4 0% 0% no-repeat padding-box;
}

.TextBc {
    display: none;
    top: -190px;
    left: -330px;
    position: absolute;
    width: 347px;
    height: 193px;
    background-color: green;
    border: 3px solid var(--unnamed-color-840c16);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 3px solid #840C16;
    border-radius: 10px 10px 0px 10px;
    opacity: 0.9;
    font: normal normal normal 13px/24px Montserrat-Regular;
}

.TextCha {
    display: none;
    top: -190px;
    left: -340px;
    position: absolute;
    width: 347px;
    height: 193px;
    border: 3px solid var(--unnamed-color-840c16);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 3px solid #840C16;
    border-radius: 10px 10px 0px 10px;
    opacity: 0.9;
    font: normal normal normal 13px/24px Montserrat-Regular;
}

.TextHanh {
    display: none;
    top: -180px;
    left: 25px;
    position: absolute;
    width: 347px;
    height: 193px;
    border: 3px solid var(--unnamed-color-840c16);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 3px solid #840C16;
    border-radius: 10px 10px 0px 10px;
    opacity: 0.9;
    font: normal normal normal 12px/24px Montserrat-Regular;
}

.TextRau {
    display: none;
    top: 30px;
    left: 15px;
    position: absolute;
    width: 347px;
    height: 193px;
    border: 3px solid var(--unnamed-color-840c16);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 3px solid #840C16;
    border-radius: 10px 10px 0px 10px;
    opacity: 0.9;
    font: normal normal normal 13px/24px Montserrat-Regular;
}

.TextMam {
    display: none;
    top: -180px;
    left: 25px;
    position: absolute;
    width: 347px;
    height: 193px;
    border: 3px solid var(--unnamed-color-840c16);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 3px solid #840C16;
    border-radius: 10px 10px 0px 10px;
    opacity: 0.9;
    font: normal normal normal 13px/24px Montserrat-Regular;
}

.Banhcuon:hover .TextBc {
    display: block;
}

.Cha:hover .TextCha {
    display: block;
}

.Hanh:hover .TextHanh {
    display: block;
}

.Rau:hover .TextRau {
    display: block;
}

.Nuocham:hover .TextMam {
    display: block;
}

.KhungHinh1 {
    width: 41px;
    height: 34px;
    -webkit-transform: matrix(-1, 0, 0, -1, 0, 0);
            transform: matrix(-1, 0, 0, -1, 0, 0);
    opacity: 1;
}

.txt {
    width: 104px;
    height: 141px;
    background: #FFBE00 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 5px;
    color: #840C16;
    text-align: left;
    font: normal normal normal 20px/25px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840C16;
    opacity: 1;
}

.color2 {
    color: white
}


/*Màn hính mobile*/

@media (min-width: 400px) and (max-width:767px) {
    .Gioithieu {
        background-image: url(/static/media/nen2.57949f6b.png);
        width: 100%;
        z-index: -1;
        height: 870px;
        opacity: 0.36;
        position: absolute;
        background-attachment: fixed;
        background-position: center;
    }
    .GT {
        position: relative;
        width: 100%;
        height: 870px;
    }
    .maket {
        background: 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
        left: 10px;
        width: 173px;
        /* height: 275px; */
        z-index: 1;
        position: absolute;
        top: -60px;
    }
    .anhtet {
        z-index: 2;
        position: absolute;
        left: 140px;
        width: 251px;
        background: 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
    }
    .Street {
        position: absolute;
        background: 0% 0% no-repeat padding-box;
        box-shadow: -5px 5px 0px #00000012;
        border-radius: 10px;
        opacity: 1;
        width: 120px;
        top: 80px;
        left: 10px;
    }
    .test {
        margin-bottom: -100px;
        margin-left: 625px;
    }
    .banhcuon {
        width: 200px;
        height: 30px;
        opacity: 1;
        margin-top: -1100px;
    }
    .Menu {
        position: relative;
        background-repeat: no-repeat;
        width: 380px;
        height: 300px;
        opacity: 1;
    }
    .Banhcuon {
        display: none;
    }
    .Cha {
        display: none;
    }
    .Nuocham {
        display: none;
    }
    .Hanh {
        display: none;
    }
    .Rau {
        display: none;
    }
    .Nav_Tab {
        position: relative;
        padding: 2px;
    }
    .nav-tabs {
        font-size: 8px;
    }
    .nav-item {
        cursor: pointer;
    }
    .KhungHinh2 {
        border-bottom: 3px solid #FFBE00;
        border-right: 3px solid #FFBE00;
        margin-right: 10px;
    }
    .notesImg-mb {
        top: 25%;
        left: 2.5%;
    }
}

@media (min-width:325px) and (max-width: 400px) {
    .Gioithieu {
        background-image: url(/static/media/Nen.d0acc695.webp);
        width: 100%;
        z-index: -1;
        height: 870px;
        opacity: 0.36;
        position: absolute;
        background-attachment: fixed;
        background-position: center;
    }
    .GT {
        position: relative;
        width: 100%;
        height: 870px;
    }
    .maket {
        background: 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
        left: 10px;
        width: 160px;
        /* height: 275px; */
        z-index: 1;
        position: absolute;
        top: -60px;
    }
    .anhtet {
        z-index: 2;
        position: absolute;
        left: 130px;
        width: 230px;
        background: 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
    }
    .Street {
        position: absolute;
        background: 0% 0% no-repeat padding-box;
        box-shadow: -5px 5px 0px #00000012;
        border-radius: 10px;
        opacity: 1;
        width: 110px;
        top: 80px;
        left: 10px;
    }
    .test {
        margin-bottom: -100px;
        margin-left: 625px;
    }
    .banhcuon {
        width: 200px;
        height: 30px;
        opacity: 1;
        margin-top: -1100px;
    }
    .Menu {
        position: relative;
        background-repeat: no-repeat;
        width: 380px;
        height: 300px;
        opacity: 1;
    }
    .Banhcuon {
        display: none;
    }
    .Cha {
        display: none;
    }
    .Nuocham {
        display: none;
    }
    .Hanh {
        display: none;
    }
    .Rau {
        display: none;
    }
    .Nav_Tab {
        position: relative;
        padding: 2px;
    }
    .nav-tabs {
        font-size: 8px;
    }
    .nav-item {
        cursor: pointer;
    }
    .KhungHinh2 {
        border-bottom: 3px solid #FFBE00;
        border-right: 3px solid #FFBE00;
        left: 72%
    }
    .txt {
        width: 104px;
        height: 141px;
        background: #FFBE00 0% 0% no-repeat padding-box;
        border-radius: 10px;
        padding: 5px;
        color: #840C16;
        text-align: left;
        font: normal normal normal 15px/25px UTMAlterGothic;
        letter-spacing: 0px;
        color: #840C16;
        opacity: 1;
    }
    .Textphone {
        font-size: 13px;
    }
    .notesImg-mb {
        visibility: hidden;
    }
}

@media (max-width:325px) {
    .Gioithieu {
        background-image: url(/static/media/nen2.57949f6b.png);
        width: 100%;
        z-index: -1;
        height: 870px;
        opacity: 0.36;
        position: absolute;
        background-attachment: fixed;
        background-position: center;
    }
    .GT {
        position: relative;
        width: 100%;
        height: 870px;
    }
    .maket {
        background: 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
        left: 10px;
        width: 140px;
        /* height: 275px; */
        z-index: 1;
        position: absolute;
        top: -60px;
    }
    .anhtet {
        z-index: 2;
        position: absolute;
        left: 120px;
        width: 200px;
        background: 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
    }
    .Street {
        position: absolute;
        background: 0% 0% no-repeat padding-box;
        box-shadow: -5px 5px 0px #00000012;
        border-radius: 10px;
        opacity: 1;
        width: 100px;
        top: 80px;
        left: 10px;
    }
    .test {
        margin-bottom: -100px;
        margin-left: 625px;
    }
    .banhcuon {
        width: 200px;
        height: 30px;
        opacity: 1;
        margin-top: -1100px;
    }
    .Menu {
        position: relative;
        background-repeat: no-repeat;
        width: 380px;
        height: 300px;
        opacity: 1;
    }
    .Banhcuon {
        display: none;
    }
    .Cha {
        display: none;
    }
    .Nuocham {
        display: none;
    }
    .Hanh {
        display: none;
    }
    .Rau {
        display: none;
    }
    .Nav_Tab {
        position: relative;
        padding: 2px;
    }
    .nav-tabs {
        font-size: 8px;
    }
    .nav-item {
        cursor: pointer;
    }
    .KhungHinh2 {
        border-bottom: 3px solid #FFBE00;
        border-right: 3px solid #FFBE00;
        left: 87%
    }
    .txt {
        width: 104px;
        height: 141px;
        background: #FFBE00 0% 0% no-repeat padding-box;
        border-radius: 10px;
        padding: 5px;
        color: #840C16;
        text-align: left;
        font: normal normal normal 15px/25px UTMAlterGothic;
        letter-spacing: 0px;
        color: #840C16;
        opacity: 1;
    }
    .Textphone {
        font-size: 12px;
    }
    .notesImg-mb {
        visibility: hidden;
    }
}

.Content{
    background: #FFFAF0 0% 0% no-repeat padding-box;
    width: 100%;
    position: relative;
    margin-bottom: 150px;
    margin-top: -250px;
}
.wraptextBoss{
    width: 405px;
     height: 436px;
     position: absolute;
     top: 180px;
     left: 264px
}
.wrap-container{
    max-width: 1600px;
    margin: auto;
    position: relative;
}
.ct-logo{
    position: absolute;
    height: 482px;
    width: 780px;
    top: -375px;
    right: 77px;
}
.ct-logo .logo{
    margin-left: 120px;
}
.ct-logo .logo-quyen{
    margin-top: 13px;
}
.ct-logo .contenthv{
    text-align: center;
    width: 351px;
    height: 63px;
    background: #FFBE00 0% 0% no-repeat padding-box;
    border-radius: 10px;
    margin: auto;
}
.ct-logo .logo-huongvi{
    position: relative;
    margin-left: 256px;
    width: 371px;
    height: 83px;
    display: flex;
}
.ct-logo .contenthv > span{
    font: normal normal normal 53px/65px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840C16;
}
.ct-logo .text-title{
    margin-top: 25px;
    display: block;
    text-align: left;
    font: normal normal normal 18px/30px Montserrat-Regular;
    letter-spacing: 0px;
    color: #392A00;
}
.ct-logo .firstP-foot{
    display: block;
    margin-top: 30px;
    text-indent: 40px;
    font: normal normal bold 30px/37px Montserrat-Bold;
    letter-spacing: 0px;
    color: #840C16;
    width: 480px;
    height: 74px;
    margin-left: auto;
}
.ct-boss{
    top: 0;
    /* left: 131px;
    position: absolute; */
    padding-top: 180px;
    height: 1000px;
    text-align: center;
    width: 1370px;
}
.ct-boss .textboss1{
    display: block;
    width: 252px;
    text-align: left;
    font: normal normal normal 18px/30px Montserrat-Regular;
    letter-spacing: 0px;
    color: #392A00;
    margin-bottom: 35px;
}
.ct-boss .textboss2{
    display: block;
    width: 441px;
    text-align: left;
    font: normal normal normal 18px/30px Montserrat-Regular;
    letter-spacing: 0px;
    color: #392A00;
}
.ct-boss .imgboss{
    position: relative;
    left: 116px;
     width: 929px;
     height: 1065px;
}
@media (max-width: 1333px) {
    .ct-boss .imgboss{
        left: 0;
    }
}
.ct-boss .nameboss{
    display: block;
    font-size: 25px;
    /* font: normal normal bold 25px/31px Muli; */
    letter-spacing: 3.75px;
    color: #FFFFFF;
    text-align: left;
}
.ct-boss .titleboss{
    display: block;
    /* font: normal normal normal 20px/25px Muli; */
    font-size: 20px;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 0.57;
    text-align: right;
}
.ct-boss .bossbr01{
    top: 52px;
    left: 0;
    position: absolute;
    z-index: 1;

}
.ct-boss .bossbr02{
    top: 52px;
    left: 0;
    position: absolute;
    z-index: 0;
    
}
.ct-boss .bossshape{
    top: -111px;
    left: 0;
    position: absolute;
    z-index: 2;
}
.ct-boss .boss1946{
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 3;
    text-align: left;
    font: normal normal normal 366px/457px UTMAlterGothic;
    letter-spacing: -18.3px;
    color: #840C16;
    text-shadow: -50px -50px 0px #00000014;
    opacity: 0.89;
}

.ct-boss .textboss{
    width: 319px;
    position: absolute;
    top: 612px;
    right: 184px;
    z-index: 3;
}
.wrap-boss{
    max-width: 1423px;
    position: relative;
    margin: 0px auto;
    height: 1245px;
    overflow: hidden;
    margin-bottom: 70px;
}
.textboxboss{
    background: #FCDE9E 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 0.82;
    height: 400px;
    width: 376px;
    position: absolute;
    top: 18px;
    left: 15px;
    text-align: left;
    font: normal normal normal 35px/44px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840C16;
}
.ct-intro-head > span{
     width: 1143px;
    height: 145px;
}
.ct-boss .borderboxtop{
    position: absolute;
     top: 0;
     left: 0;
     width: 57px;
     height: 46px;
     border-top: 5px solid #840C16;
     border-left: 5px solid #840C16;
    border-top-left-radius: 5px 5px;
}
.ct-boss .borderboxbottom{
    position: absolute;
     bottom: 0;
     right: 0;
     width: 57px;
     height: 46px;
     border-bottom: 5px solid #840C16;
     border-right: 5px solid #840C16;
    border-bottom-right-radius: 5px 5px;
}
.ct-intro-body .col5-custom{
    align-items: flex-end;
    padding: 0;
    margin: 0;
}
.ct-multiRow{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.ct-multiRow .fisrtimgs{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.ct-multiRow .secondimgs{
    display: flex;
    justify-content: space-between;
}
.ct-intro-head{
    margin-top: 67px;
    text-align: left;
    font: normal normal normal 18px/30px Montserrat-Regular;
    letter-spacing: 0px;
    color: #392A00;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ct-intro-head .upper{
    text-align: left;
    font: normal normal 600 18px/30px Montserrat-SemiBold;
    letter-spacing: 0px;
    color: #840C16;
}
.ct-intro-head .huongvidicung{
    position: absolute;
    top: 30px;
    left: 23px;
    display: block;
    width: 420px;
    height: 74px;
    text-align: left;
    text-indent: 20px;
    font: normal normal bold 30px/37px Montserrat-Bold;
    letter-spacing: 0px;
    color: #840C16;
}
.box1hv{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 41px;
    height: 33px;
    border-top: 3px solid rgb(252, 222, 158);
    border-left: 3px solid rgb(252, 222, 158);
}
.myfix-padding-mobile{
    padding: 25px;
}
.box2hv{
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 41px;
    height: 33px;
    border-bottom: 3px solid rgb(252, 222, 158);
    border-right: 3px solid rgb(252, 222, 158);
}
.textboxboss > span{
    display: block;
    width: 341px;
    height: 371px;
    padding-top: 23px;
    padding-left: 32px;
}
.ct-intro-body{
    margin-top: 74px;
}
.ct-intro-body .textrow1{
    
    min-width: 260px;
}
span{
    display: block;
}
.textrow1 > span ,.textrow2 > span{
    display: block;
}
.ct-intro-body .col-nth3{
    padding: 0;
    margin: 0;
    text-align: end;
    justify-content: end;
}
.body-row1 > div:nth-child(4){
    padding: 0;
    margin: 0;
    align-self: flex-start;
}   
.col-nth3 > div:first-child{
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}
.col-nth3-div{
    padding-top: 14px;
}
.col-nth3-1{
   position: relative;
    left: 18px;
}
.myfiximg-01{
    /* align-self: flex-end; */
}
.myfiximg-01 > img{
   width: 85.7%;
}
.footer-custom{
    background-color: #ffb300;
}
.textrow1-head{
    text-align: left;
    letter-spacing: 0px;
    color: #392A00;
    font: normal normal normal 15px/30px Montserrat-Regular;
}
.textrow1-body{
    text-align: left;
    font: normal normal normal 40px/50px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840C16;
}
.textrow1-foot{
    margin-top: 13px;
    text-align: left;
    font: normal normal normal 15px/20px Montserrat-Regular;
    letter-spacing: 0px;
    color: #392A00;
}
.ct-intro-footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 97px;
}
.ct-intro-footer > img{
    margin-bottom: 39px;
}
.footer-row1{
    width: 1057px;
    text-align: center;
    font: normal normal 30px/37px Montserrat-Bold;
    font-weight: 900;
    letter-spacing: 0px;
    color: #840C16;
}
.text-title span{
    display: inline;
}
.footer-row1 span{
    display: inline;
    font: normal normal bold 40px/37px Montserrat-Bold;
    color: #FFBE00;
}
.footer-row2{
    display: block;
    text-align: center;
    font: normal normal normal 13px/20px Montserrat-Regular;
    letter-spacing: 0px;
    color: #392A00;
}
.footer-row2{
    width: 733px;
    margin-top: 16px;
}
.mycarouselimg{
    border-radius: 5px;
    width: 817px;
    height: 105px;
    position: absolute;
    bottom: 16px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
#carouselimg{
    width: 100%;
    height: 100%;
    position: relative;
}
.myinnerimg{
    position: absolute;
    top: 15px;
    width: unset;
    left: 10px;
    height: 77px;
}
.pre-btncarimg{
    position: absolute;
    left: -18px;
    width: 0;
}
.next-btncarimg{
    position: absolute;
    right: -18px;
    width: 0;
}
.slide-box > img{
    padding: 0 4px;
}
.ct-intro-body .body-row1{
    align-items: flex-end;
}
.body-row2{
    
}
.wrap-textrow1{
    /* margin-left: 26px;
    margin-bottom: 26px; */
    padding: 25px;
}
.textrow1-head .custom-text{
    font: normal normal 600 15px/30px Montserrat-SemiBold;
    display: inline-block;
}
.ct-intro-head span{
    display: inline;
}
@media (max-width: 1200px) {
    .wrap-textrow1{
        width: 69%;
    }
    .textrow1-head, .textrow1-body, .textrow1-foot, .textrow1-head .custom-text{
        font-size: 13px;
    }
    .mycarouselimg{
        width: 645px;
    }
    .myinnerimg{
        left: 6px;
    }
    .textrow1-body{
        font-size: 30px;
        line-height: 40px;
    }
    
}
@media  (min-width :1200px) and (max-width:1650px) {
    .ct-intro-body
    {
        width:100%;
        padding-left:10px;
        padding-right: 10px;
    }
}
@media (min-width:1650px) {
    .ct-intro-body
    {
        width:100%;
    }
}
.contentfooter {
    background-image: url(/static/media/backgroundFooter.a3be03ca.webp);
    background-repeat: no-repeat;
    width: 100%;
    /* height: 1366px; */
    opacity: 1;
    background-size: cover;
    position: relative;
    /* top: -471px; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
}

.contactFooter {
    padding: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* position: absolute; */
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 13px;
}
.logo-footer {
    position: absolute;
    left: 3%;
}
.logoQ {
    visibility: hidden;
}
.contact-address {
    left: 15%;
    width: 25%;
    position: absolute;
}

.shop2 {
    top: 17.8%;
    width: 25%;
    position: absolute;
    left: 42%;
}

.like-facebook {
    position: absolute;
    top: 90px;
    left: 74%;
    width: 20%;
    height: 166px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.borderboxbottom {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 57px;
    height: 46px;
    border-bottom: 5px solid #840c16;
    border-right: 5px solid #840c16;
    border-bottom-right-radius: 5px 5px;
}

.shadow-contact {
    position: absolute;
    bottom: -15px;
    left: -15px;
    width: 1140px;
    height: 287px;
    background: #ffbe00 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 0.78;
    z-index: -1;
}

.infor-contact {
    margin-top: 69px;
    display: flex;
    align-items: center;
    /* position: absolute; */
    /* bottom: 15px;
    left: 15px; */
    text-align: center;
    position: relative;
    height: 287px;
    background: #fcde9e 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
}

.copyright {
    margin-top: 54px;
    margin-bottom: 69px;
    text-align: center;
    font: normal normal 600 16px/30px Montserrat-Regular;
    letter-spacing: 0px;
    color: #840c16;
    opacity: 1;
}

.contact-address>.contact-header {
    text-align: left;
    font: normal normal normal 30px/38px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840c16;
    opacity: 1;
    margin-bottom: 21px;
}

.shop2>.contact-header {
    height: 37px;
    text-align: left;
    font: normal normal normal 20px/25px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840c16;
    opacity: 1;
}

.contact-body {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
}

.contact-body>span {
    text-align: left;
    font: normal normal normal 15px/21px SFUFuturaBook;
    letter-spacing: 0px;
    color: #392a00;
}

.strong-text {
    display: inline;
    text-align: left;
    font: normal normal bold 15px/21px SFUFuturaBold;
    letter-spacing: 0px;
    color: #392a00;
}

.breaking-news {
    position: relative;
}

.carousel-inner::-webkit-scrollbar{
    width: 0 !important;
    display: none;
}
.img-carousel {
    height: 166px;
    border-radius: 5px;
    width: 50%;
}

.text-news {
    padding: 0 10px;
    display: block;
    /* width: 100%; */
    /* max-width: 187px; */
    height: 100%;
    max-height: 139px;
    text-align: left;
    font: normal normal normal 13px/20px Montserrat-Regular;
    letter-spacing: 0px;
    color: #392a00;
}

.mybtn-pre {
    width: 19px;
    position: absolute;
    left: -81px;
}

.mybtn-next {
    width: 19px;
    position: absolute;
    right: -81px;
}

.text-news-header {
    font: normal normal normal 20px/25px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840c16;
 
    line-height: 1.5rem;
    height: 4.5rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.text-news-body {
    margin-top: 6px;
    width: 100%;
    
    align-items: 'center';
    justify-content: 'center';
    font: normal normal normal 13px/20px Montserrat-Regular;
    letter-spacing: 0px;
    color: #392a00;
}

.text-news-date {
    font: normal normal bold 13px/20px Montserrat-Bold;
    letter-spacing: 0px;
    color: #fcde9e;
}

/* rating */

.rating {
    background-image: url(/static/media/rating01.aee654f7.webp);
    width: 100%;
    height: 100%;
    position: absolute;
}

.rating-card img {
    width: 100%;
    height: 100%;
}

.tintucmoinhat {
    color: var(--unnamed-color-840c16);
    text-align: left;
    font: normal normal normal 53px/66px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840c16;
    opacity: 1;
    padding: 0;
    margin-bottom: 77px;
}

.contact-rowtext>span {
    display: inline;
}

.text-news-body {
    font-size: 9px;
    line-height: 1.5rem;
    height: 4.5rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

}

.text-news {
    height: 13.75%;
}

.text-news-date {
    font-size: 8px;
}

.CommentCarousel {
    width: 400px;
    position: absolute;
    top: 10%;
    right: 9%;
    text-align: left;
    font: normal normal normal 18px/30px Montserrat-Regular;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    z-index: 100;
}

.wrap-tintucmoinhat {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 477px;
}

.slide-mobie-footer {
    display: none;
}

.slide-mobie-footer ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    list-style: none;
    padding-left: 0;
    position: relative;
    max-width: 100%;
    margin-left: 13px;
    overflow-x: auto;
    overflow-y: hidden;
    height: 220px;
}

.slide-mobie-footer li {
    display: flex;
    flex-shrink: 0;
    padding-right: 8px;
    width: 350px;
}

.fisrtimgs img:nth-child(1) {
    padding-bottom: 30px;
}

.fisrtimgs img:nth-child(2) {
    padding-bottom: 30px;
}

.fisrtimgs img:nth-child(3) {
    padding-bottom: 30px;
}

.fix-imgFooter {
    display: none;
    position: absolute;
    width: 900px;
    top: -117px;
    height: 463px;
}

.slide-pc-footer
{
    margin-top: 400px;
}
.slideFooter ul{
    padding: 0;
    display: flex;
    list-style: none;
    padding-left: 0;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    height: 200px;
    width: 100%;
    align-items: center;
    margin:0 auto;
    margin-left: 70px;
}
.slideFooter ul::-webkit-scrollbar{
    display: none;
}
.slideFooter
{
    display: flex;
    align-items: center;

}
@media (min-width: 1200px) and (max-width: 1650px) {
    .contentfooter {
        margin-top: -420px;
    }
}

@media (min-width: 1200px) and (max-width: 1482px) {
    .wrap-container {
        padding-left: 30px;
    }
    .ct-logo {
        right: 0;
    }
}

@media (min-width: 1649px) {
    .contentfooter {
        margin-top: -530px;
    }
}

@media (max-width: 755px) {
    .ct-multiRow .fisrtimgs img{
        height: 140px;
    }
    .news-re-mobile {
        flex-direction: column-reverse;
    }
    .wrap-News {
        margin-bottom: -200px !important;
    }
    .Commnet,
    .Text {
        font-size: 13px !important;
    }
    .Commnet {
        line-height: 20px !important;
    }
    .TitileNews {
        font: normal normal normal 34px/51px UTMAlterGothic !important;
    }
    .news-re-mobile>div:nth-child(2) {
        padding-left: 0;
    }
    .news-re-mobile pre {
        letter-spacing: 0.5px;
        font-size: 12px !important;
    }
    .ct-intro-body .body-row1 iframe {
        height: 199px;
    }
    .ct-intro-body .body-row1 {
        margin: 0;
        padding: 0 3px;
    }
    .wrap-nameboss{
        right: 100px !important;
    }
    .fix-imgFooter {
        display: block;
    }
    .wrap-tintucmoinhat {
        display: none;
    }
    .slide-mobie-footer {
        position: relative;
        display: block;
        z-index: 10;
    }
    .wrap-boss {
        width: unset;
        height: unset;
    }
    .ct-boss {
        width: unset;
        height: 600px;
        padding-top: 36px;
    }
    .Content {
        background: unset;
    }
    .imgboss {
        height: 550px;
        width: 325px;
    }
    .ct-boss .bossbr01 {
        width: 500px;
    }
    .ct-boss .bossbr02 {
        width: 500px;
    }
    .ct-boss .bossshape {
        top: -51px;
        left: 0;
        width: 575px;
    }
    .ct-boss .boss1946 {
        top: 438px;
        left: 4px;
        bottom: 30px;
        font: normal normal normal 182px/92px UTMAlterGothic;
        text-shadow: -31px -31px 0px #00000014;
    }
    .ct-boss>.w {
        display: none;
    }
    .ct-boss .imgboss {
        left: 52px;
        width: unset;
        height: unset;
    }
    .wraptextBoss {
        width: 210px;
        height: 250px;
        left: 3px;
        top: 30px;
    }
    .ct-multiRow {
        padding: 0 3px;
    }
    .textboxboss {
        width: 210px;
        height: 250px;
        font: normal normal normal 18px/23px UTMAlterGothic;
    }
    .textboxboss>span {
        padding: 26px;
        width: unset;
        height: unset;
    }
    .textboxboss {
        top: unset;
        left: unset;
    }
    .ct-boss .borderboxtop {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 30px;
        height: 24px;
        border-top: 3px solid #840c16;
        border-left: 3px solid #840c16;
        border-top-left-radius: 3px 3px;
    }
    .ct-boss .borderboxbottom {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 30px;
        height: 24px;
        border-bottom: 3px solid #840c16;
        border-right: 3px solid #840c16;
        border-bottom-right-radius: 3px 3px;
    }
    .ct-boss .textboss {
        width: 256px;
        position: absolute;
        top: 329px;
        left: 65px;
        z-index: 3;
    }
    .fisrtimgs {
        margin: 0;
    }
    .fisrtimgs img:first-child {
        padding: 0;
        padding-right: 5px;
    }
    .fisrtimgs img:nth-child(2) {
        padding: 0;
    }
    .fisrtimgs img:nth-child(3) {
        padding: 0;
        padding-top: 5px;
        padding-right: 5px;
    }
    .fisrtimgs img:nth-child(4) {
        padding: 0;
        padding-top: 5px;
    }
    .fisrtimgs img:nth-child(5) {
        padding: 0;
        padding-top: 5px;
    }
    .ct-intro-head {
        margin-top: 15px;
    }
    .ct-intro-head>span {
        width: 94%;
        height: 100%;
        font-size: 15px;
    }
    .ct-intro-head>div {
        left: -38px;
        top: 20px;
    }
    .textrow1-head {
        line-height: 17px;
        margin-bottom: 9px;
    }
    .textrow1-body {
        font-size: 25px;
        line-height: 31px;
    }
    .wrap-textrow1 {
        padding: 0 13px 13px;
    }
    .ct-intro-body .col5-custom {
        align-items: flex-start;
    }
    .ct-intro-body .col-nth3 {
        padding: 0;
        margin: 0;
        text-align: end;
        justify-content: end;
        flex-direction: column-reverse;
    }
    .col-nth3-div {
        padding: 0 24px;
    }
    .col-nth3>div:first-child {
        left: 0;
        margin-top: 22px;
    }
    .myfiximg-01>img { 
        width: 100%;
    }
    .ct-intro-body .col5-custom {
        margin-top: 22px;
    }
    .body-row1>div:nth-child(4) {
        margin-top: 22px;
    }
    .logo-footer {
        visibility: hidden;
    }
    .infor-contact {
        height: 750px;
        width: 100%;
        background: #fcde9e 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
    }
    .shadow-contact {
        position: absolute;
        bottom: -15px;
        left: -15px;
        width: 100%;
        height: 750px;
        background: #ffbe00 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 0.78;
        z-index: -1;

    }
    .logoQ {
        position: absolute;
        visibility: visible;
        top: 3%;
        left: 30%;
        width: 35%;
        height: 12%;

    }
    .contact-address {
        align-self: flex-start;
        width: 100%;
        top: 18%;
        position: absolute;
    }
    .shop2 {
        position: absolute;
        width: 100%;
        top: 45%;
        left: 18%;
    }
    .like-facebook {
        position: absolute;
        width: 100%;
        top: 75%;
        left: 38%;
    }
    .icon-ytb-zalo {
        width: 200px;
        align-items: flex-start;
        justify-content: center;
        height: 115px;
    }
    .contact-address>.contact-header,
    .contact-body {
        width: 267px;
    }
    .copyright {
        font: normal normal 600 9px/20px Montserrat-Regular;
        margin-top: 30px;
        margin-bottom: 41px;
        height: 100px;
    }
    .logo-footer {
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .tintucmoinhat {
        font: normal normal normal 30px/38px UTMAlterGothic;
        margin-left: 13px;
        margin-bottom: 32px;
        position: relative;
        padding-top: 41px;
    }
    .CommentCarousel {
        width: 105px;
        font: normal normal normal 12px/22px Montserrat-Regular;
    }
    .footer-row1,
    .footer-row2 {
        width: unset;
    }
    .footer-row1 {
        font: normal normal 18px/27px Montserrat-Bold;
    }
    .footer-row1 span {
        display: inline;
        font: normal normal bold 22px/27px Montserrat-Bold;
        color: #ffbe00;
    }
    .ct-intro-footer>img {
        margin-bottom: 20px;
    }
    .ct-intro-footer {
        margin: 20px 5px 0;
    }
    .Content {
        margin-bottom: 30px;
    }
    .ct-intro-head .huongvidicung {
        width: 284px;
        left: 18%;
        font: normal normal bold 22px/27px Montserrat-Bold;
    }
    .ct-intro-body {
        margin-top: 50px;
    }
    .nen,
    .nen01 {
        height: 100vh;
    }
    .wrap-boss {
        margin-bottom: 30px;
        padding-left: 3px;
    }
    .ct-logo {
        display: flex;
        flex-direction: column;
    }
    .ct-logo .contenthv>span {
        font: normal normal normal 28px/35px UTMAlterGothic;
        padding: 0 18px;
    }
    .ct-logo .logo-huongvi {
        width: 231px;
        height: unset;
        margin-top: 20px;
        margin-left: 15%;
    }
    .ct-logo .contenthv {
        border-radius: 5px;
        height: 41px;
    }
    .box1hv {
        top: -7px;
        left: -7px;
        width: 27px;
        height: 21px;
    }
    .box2hv {
        bottom: -7px;
        right: -7px;
        width: 27px;
        height: 21px;
    }
    .ct-logo {
        right: 0;
        width: unset;
        padding-right: 3px;
    }
    .ct-logo .text-title {
        font: normal normal normal 12px/22px Montserrat-Regular;
        align-self: center;
        max-width: 340px;
    }
    .ct-logo .firstP-foot {
        max-width: 340px;
        margin-top: -2px;
        font: normal normal bold 20px/27px Montserrat-Bold;
    }
    .ct-logo>div:first-child>img:first-child {
        width: 134px;
    }
    .ct-logo>div:first-child {
        align-self: flex-end;
        margin-right: 6px;
    }
    .ct-logo>div:nth-child(2) {
        align-self: flex-end;
        margin-right: 6px;
    }
    .ct-logo>div:nth-child(3) {
        display: flex;
        flex-direction: column;
    }
    .ct-logo .logo {
        width: 48px;
        margin-left: 24px;
    }
    .ct-logo .logo-quyen {
        width: 234px;
    }
    .ct-logo .firstP-foo {
        margin-left: 0;
    }
    .ct-intro-footer>img {
        width: 118px;
    }
    .myfix-padding-mobile {
        padding-top: 0;
    }
    .my-imgMenu {
        width: 164px;
        height: 243px;
    }
    .nav-tabs {
        overflow-x: auto;
        max-width: 100%;
        flex-wrap: nowrap;
        overflow-y: hidden;
    }
    .nav-tabs::-webkit-scrollbar{
        width: 0 !important;
        display: none;
    }
    .nav-item {
        flex-shrink: 0;
        padding: 11px 0;
    }
    .scroll-mobile {
        overflow-x: auto;
        max-width: 100%;
    }
    .scroll-mobile::-webkit-scrollbar{
        width: 0 !important;
        display: none;
    }
    .myText-carousel {
        top: 40%;
        left: 5%;
        z-index: 2;
    }
    .history {
        font: normal normal normal 30px/45px Montserrat-Regular;
    }
    .text {
        font: normal normal normal 12px/25px Montserrat-Regular;
        margin-top: unset;
    }
    .imgZoom {
        height: 100vh;
    }
    .slide-pc-footer
    {
        display: none;
    }
}

@media (min-width: 701px) and (max-width: 1180px) {
    .fisrtimgs img:nth-child(4) {
        padding-bottom: 30px;
    }
}

@media (min-width: 756px) and (max-width: 993px) {
    .infor-contact {
        padding-left: 50%;
        padding-right: 50%;
    }
    .shadow-contact {
        width: 100%;
    }
    .like-facebook {
        position: absolute;
    }
    .icon-ytb-zalo {
        flex-direction: row;
        width: 180px;
    }
    .contact-address {
        top: 15%;
        left: 11%;
        width: 30%;
        position: absolute;
    }
   .logo-footer {
        width: 70px;
        height: 90px;
    }
    .shop2 {
        top: 15%;
        left: 41%;
        position: absolute;
        width: 24%;
    }
    /*News*/
    /* .img-carousel {
        width: 30%;
        height: 100%;
    } */
    
    .slideFooter {
        overflow-x: auto;
        overflow-y: hidden;
    }
}
@media (max-width: 375px) {
    .news-re-mobile pre {
        letter-spacing: 0.5px;
        font-size: 11px !important;
    }
}
.breackcum-introbody{
    background-color: rgb(132, 12, 22,.54);
    height: 55px;
    margin-top: -55px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
}
.breackcum-introbody ul{
    padding: 0;
    list-style: none;
    margin: 0;
    display: inline;
}
.breackcum-introbody li{
    display: inline;
    margin-right: 32px;
    color: #fff;
    font: normal normal normal 16px/21px SFUFuturaBook;
}
.section1-bg{
    background-color: rgb(132, 12, 22);
    height: 791px;
    overflow: hidden;
    position: relative;
    top: -1px;
}
.img-section1{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-radius: 50%;
    width: 100%;
    height: 1140px;
    overflow: hidden;
    position: relative;
}

.section1-bg span{
    right: 0;
    top: 19%;
    position: absolute;
    display: block;
    width: 471px;
    text-indent: 51px;
    color: #FFBE00;
    font: normal normal bold 30px/37px Montserrat-Bold;
}
.section1-bg .groupimg{
    position: absolute;
    top: 45%;
    left: 3%;
    width: 439px;
    height: 217px;
}
.section2-intro .firstP-section2{
    
    width: 100%;
    text-align: center;
    font: normal normal normal 20px/30px Montserrat-Regular;
    color: #707070;
    padding: 55px 0;
}
.section2-intro{
    position: relative;
} 
span .upperBoldText{
    color: #840C16;
    display: inline;
    font: normal normal 600 20px/30px Montserrat-SemiBold;
}
.section2-intro .imgcircle{
    margin-bottom: 15px;
    width: 74px;
    height: 74px;
    border-radius: 50%;
    border: 3px solid #FCDE9E;
    overflow: hidden;
}
.section2-intro .imgcircle > img{
    position: relative;
    width: 110%;
    top: -3px;
    left: -3px;
    right: -3px;
}
.col-section2{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.AnhHai{
    font-family: muli, sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #840C16;
    letter-spacing: 2px;
    margin-bottom: 6px;
    font-size: 15px;
    text-align: center;

}
.AnhHai-title{
    letter-spacing: 0px;
    color: #840C16;
    text-transform: uppercase;
    opacity: 0.57;
    text-align: center;
    font: normal normal normal 12px/15px Muli;
    font-family: muli, sans-serif;
    font-style: normal;
    font-size: 12px;
}
.secondP-row1 , .secondP-row2, .secondP-row3{
    display: flex;
    align-items: flex-start;
    margin-bottom: 22px;
}
.secondP{
    width: 75%;

}
.secondP-section2{
    font: normal normal normal 18px/30px Montserrat-Regular;
    color: #392A00;
}
.secondP .heading {
    font: normal normal normal 35px/44px UTMAlterGothic;
    color: #840C16;
    padding-bottom: 21px;
}
.secondP .bodying, .Montserrat-Regular1830 {
    font: normal normal normal 18px/30px Montserrat-Regular;
    color: #392A00;
}
.img-ab-section2{
    z-index: -1;
    opacity: .9;
    position: absolute;
    bottom: 13%;
    right: -22%;
}

/* section 3 */


.section3-intro{
    margin-top: 61px;
}
.headingSection3{
    width: 100%;
}
.firstP-section3{
    padding-top: 61px;
    margin: 0 auto;
    /* width: 90%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.firstP-section3 .normal-text{
    margin-top: 40px;
    text-indent: 46px;
    text-align: center;
    font: normal normal 30px/37px Montserrat-Bold;
    font-weight: 900;
    letter-spacing: 0px;
    color: #840C16;
}
.firstP-section3 .color-text{
    display: inline;
    font: bold 40px / 37px Montserrat-Bold;
    color: rgb(255, 190, 0);
}
.secondP-section3{
    margin-top: 37px;
}
.thirdP-section3{
    margin-top: 37px;
}
.fourthP-section3{
    margin: 61px 0;
}
.fourthP-section3 > span{
    text-indent: 26px;
    padding: 0;
    color: #392A00;
    font: italic 18px/30px Montserrat-BoldItalic;
    text-align: right;
}

.wrapimg-sm-boss{
    position: relative;
    height: 157px;
    width: 157px;
    margin-left: auto;
    overflow: hidden;
    border-radius: 50%;
}
.wrapimg-sm-boss > img{
    position: absolute;
    top: 0;
    left: 0;
}
.wrap-nameboss > .name{
    font: normal normal bold 9px/11px Muli;
    letter-spacing: 1.75px;

    color: #FCDE9E;
    text-align: left;
}
.wrap-nameboss{
    z-index: 5;
    width: 105px;
    position: absolute;
    right: 11px;;
    top: 125px;
    height: 20px;
}
.wrap-nameboss > .title{
    font: normal normal bold 6px/8px Muli;
    color: #840C16;
    text-align: right;
}
/* car */
.slick-slide > div{
    display: flex;
    height: 100%;
}
.slick-list, .slick-track{
    height: 100%;
}
.slick-initialized .slick-slide {
    margin: 0 7px;
}
.col1-Footer{
    position: relative;
}
.col1-Footer > div{
    color: #FFBE00;
    padding: 0 20px;
    bottom: 20px;
    position: absolute;
}
.col2-Footer{
    height: 100%;
    padding: 7px;
     background-color: #FFFAF0;
}
.col2-Footer > span {
    margin: 8px;
}
.col3-Footer div{
    background-color: #FFFAF0;
    padding: 13px;
}
.col3-Footer > .row2{
    margin: 15px 0;
}
.col2-Footer > .head, .col3-Footer .head{
    font: normal normal normal 20px/25px UTMAlterGothic;
    color: #840C16;
}
.col2-Footer > .title, .col3-Footer .title{
    font: normal normal normal 13px/20px Montserrat-Regular;
    color: #392A00;
}
.col2-Footer > .time, .col3-Footer .time{
    font: normal normal normal 10px/20px Montserrat-Regular;
    color: #FCDE9E;
}
.col3-Footer .title{
    padding: 8px 0;
}
.doctiep{
    display: inline;
    color: #840C16;
    font: normal normal bold 13px/18px Montserrat-Bold;
}
.col1-Footer,.col2-Footer,.col3-Footer, .row1, .row2, .row3{
    border-radius: 5px;
}
.footer-custom .title-head{
    font: normal normal normal 53px/66px UTMAlterGothic;
    color: #840C16;
}
.footer-custom .title-more{
    font: normal normal normal 12px/20px Montserrat-Regular;
    color: #FFFAF0;
    line-height: 66px;
    cursor: pointer;
}
.section1-img-family-phone
    {
        display:none
        
    }
.section3-intro{
    margin-bottom: 318px;
}
@media (max-width:755px)
{
   
    .section1-bg{
        height: 330px;
    }
    .img-section1{
       
        height:384px;
       
       
    }
    .section1-bg span{
        right: 0;
        top: 3%;
        display: block;
        width: 170px;
        text-indent: 20px;
        font: normal normal bold 20px/30px Montserrat-Bold;
    }
    .groupimg-banhcuon
    {
        width: 70px;
        padding: 0px;
        margin-left: 3%;
    }
    .groupimg-icon
    {
        width:25.13px;
        margin-left: 50px;
        margin-top: 6px;
        
    }
    .groupimg-logo{
        width:150px;
        height:50px;
        padding: 0px;
    }
    .section1-bg .groupimg{
        position: absolute;
        top: 47%;
        left: 8%;
        width: 200px;
    }
    .section1-img-family-desktop
    {
        display:none
        
    }
    .section1-img-family-phone
    {
        display:block
        
    }
    .section2-intro .firstP-section2{
    
      
        font: normal normal normal 15px/22px Montserrat-Regular;
        color: #707070;
        
    }
    .secondP-section2{
        font: normal normal normal 15px/22px Montserrat-Regular;
    }
    .img-ab-section2{
        bottom: 13%;
        right: -60%;
    }
    .firstP-section3 .normal-text{
        margin-top: 40px;
        text-indent: 0px;
        text-align: center;
        font: normal normal 20px/30px Montserrat-Bold;
        font-weight: 900;
        letter-spacing: 0px;
        color: #840C16;
    }
    .firstP-section3 .color-text{
        
        font: bold 25px / 30px Montserrat-Bold;
        
    }
    .secondP-section3{
        font: normal normal normal 15px/22px Montserrat-Regular;
    }
    .thirdP-section3{
        font: normal normal normal 15px/22px Montserrat-Regular;
    }
    .fourthP-section3{
        margin: 61px 0;
        
    }
    .fourthP-section3 > span{
        font: italic 18px/30px Montserrat-BoldItalic;
    }
    .wrapimg-sm-boss{
        
        width: 157px;
        margin: 0 auto;
        margin-bottom: 50%;
     
    }
  
   
    .Footerimg{
       display:none
    }
    .col3-Footer
    {
        overflow-x: auto;
        display: flex;
        max-width: 100%;
    }
    .col3-Footer div {
        background-color: #FFFAF0;
        padding: 13px;
        flex-shrink: 0;
        /* height: 200px; */
        width: 100vw;
        margin-right: 20px;
        
    }
    .col3-Footer > .row2{
        margin: 0px 0;
        margin-right: 20px;
    
    }
    .iframefooter
    {
        height:572px;
        padding:10px
    }
    .title-more{
        display:none
    }
    .footer-custom .title-head{
        font: normal normal normal 30px/40px UTMAlterGothic;
        padding:10px
    }
    .section3-intro{
        margin-bottom: -318px;
    }
}
.New
{
    background: #840C16 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    width: 274px;
    height: 92vh;
    
}
.bodyNews
{
    margin-top: 100px;
}
@media (min-width: 700px) {
    .news-pl{
        padding-left: 40px;
    }
    .news-re-mobile > div:nth-child(2){
        padding-left: 36px;
    }
    .mobilenew
    {
        display: none;
    }
}
@media (max-width: 755px)
{
    .img1{
        height:269px
    }
}
.TitileNews
{
    margin-top: 30px;
    text-align: left;
    font: normal normal normal 53px/66px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840C16;
    opacity: 1
}
.Review
{
    text-align: left;
    font: normal normal normal 25px/31px UTMAlterGothic;
    letter-spacing: 0px;
    color: #840C16;
    opacity: 1;
    border-left: 5px solid;
    border-color:#FFBE00 ;
    padding-left:20px
}
.wrap-News{
    margin-bottom: 470px;
}
.Text1
{
    text-align: left;
    font: normal normal normal 18px/30px Montserrat;
    letter-spacing: 0px;
    color: #392A00;
    opacity: 1;
}
.Commnet
{
    text-align: left;
    font: italic normal 600 18px/30px Montserrat;
    letter-spacing: 0px;
    opacity: 1
}
.NameNews
{
    text-align: left;
    font: normal normal normal 15px/19px UTMAlterGothic;
    letter-spacing: 0px;
    color: #FFBE00;
    opacity: 1;
}
.Titile
{
    text-align: center;
    font: normal normal bold 18px/30px Montserrat;
    letter-spacing: 0px;
    color: #FCDE9E;
    text-transform: uppercase;
    opacity: 1;
}
.allNew
{
    text-align: left;
    font: normal normal normal 15px/20px Montserrat;
    letter-spacing: 0px;
    color: #FCDE9E;
    opacity: 1;
    
}
.DateNew
{
    text-align: left;
    font: normal normal bold 8px/12px Montserrat;
    letter-spacing: 0px;
    color: #FCDE9E;
    opacity:1
}
.slideNewsDetail
{
    height:75vh;
    overflow-y: auto;
    position: relative;
}
.Commnet img{
    width: 100%;
}
.Commnet
{
    font: inherit;
}

#video
{
    width: 100% !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@media  (min-width :756px) and (max-width:1649px) {
  iframe
  {
    width:550px;
    height:500px
  }
  .Dathangphone
  {
    display: none;
  }
  .iconBack
  {
    width:100px
  }
  .DatHangNhanh
  {
    display: none;
  }
}
@media (min-width:1650px) {
  iframe
  {
    width:550px;
    height:600px
  }
   .Dathangphone
  {
    display: none;
  }
  .iconBack
  {
    width:150px
  }
  .DatHangNhanh
  {
    display: none;
  }
}
@media (max-width :755px) 
{
   .DatHangNhanh{
     width: 230px;
     height: 53px;
     color: #FCDE9E;
     font: normal normal normal 24px/32px UTMAlterGothic;
     background-color: #840C16;
     border-radius: 5px;
     text-align: center;
     line-height: 53px;
     position: relative;
     bottom: 20px;
     right: 9px;
     
   }
   .DatHangNhanh span{
    -webkit-animation: pulse 2s infinite,shake 1s linear infinite;
            animation: pulse 2s infinite,shake 1s linear infinite;

   }
   .box-first{
     position: absolute;
     width: 28px;
     height: 23px;
     border-top: 2px solid #FFBE00;
     border-left: 2px solid #FFBE00;
     top: 8px;
     left: 15px;
   }
   .box-last{
    position: absolute;
    width: 28px;
    height: 23px;
    border-bottom: 2px solid #FFBE00;
    border-right: 2px solid #FFBE00;
    bottom: 8px;
    right: 15px;
  }
  .MenuiFrame
  {
    height: 450px;
    width: 100%;
    
  }
  .DathangPC
  {
    display:none
  }
  .iconBack
  {
    width:50px
  }
  .modal-menu
  {
     position: fixed;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background-color: rgb(0,0,0,.4);
    z-index: 0 !important;
    width: 100%;
  }
  .modaliframe
  {
    position: absolute;
    bottom:0;
    width: 100%;
  }
}
@-webkit-keyframes shake{
  0% {
      -webkit-transform: translate(3px,0);
              transform: translate(3px,0);
  }
  5% {
     -webkit-transform: translate(-3px,0);
             transform: translate(-3px,0);
 }
 10% {
     -webkit-transform: translate(0,0);
             transform: translate(0,0);
 }
}
@keyframes shake{
  0% {
      -webkit-transform: translate(3px,0);
              transform: translate(3px,0);
  }
  5% {
     -webkit-transform: translate(-3px,0);
             transform: translate(-3px,0);
 }
 10% {
     -webkit-transform: translate(0,0);
             transform: translate(0,0);
 }
}
@-webkit-keyframes pulse {
 0% {
   -webkit-box-shadow: 0 0 0 0 rgba(255,190,0, 0.4);
 }
 70% {
     -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
 }
 100% {
     -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
 }
}
@keyframes pulse {
 0% {
   box-shadow: 0 0 0 0 rgba(255,190,0, 0.4);
 }
 70% {
     box-shadow: 0 0 0 10px rgba(255,190,0, 0);
 }
 100% {
     box-shadow: 0 0 0 0 rgba(255,190,0, 0);
 }
}
.hotline{
    display: none;
    position: fixed;
    z-index: 99999;
    bottom: 15%
}
@media (max-width: 700px) {
    .hotline{
        display: block;
    }
}
.card-text-name
{
    text-align: left;
    font: normal normal normal 18px/25px UTM Alter Gothic;
    letter-spacing: 0px;
    color: #840C16;
    opacity: 1;
    padding-top: 10px;
    height: 90px;
    
}
.card-text-date
{
    text-align: left;
    font: normal normal normal 10px/20px Montserrat;
    letter-spacing: 0px;
    color: #FCDE9E;
    opacity: 1;
    margin-bottom: 0px;
    
}
.card-text-comment{
    text-align: left;
    font: normal normal normal 12px/20px Montserrat;
    letter-spacing: 0px;
    color: #392A00;
    opacity: 1;
    height: 60px;
    /* line-height: 1.5rem;*/
     /* height: 4.5rem; */
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  
}
.card{

    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000001A;
    border-radius: 5px;
    opacity: 1;
    padding-top: 10px;
    height: 100%;
 
}
.pagination
{
    position: absolute;
    left: 50%;
  
    -webkit-transform: translateX(-50%);
  
            transform: translateX(-50%);
}
.hjFLZP
{
    top:50% !important;
    height:0
}
.imageNews {
    height: 301px;
    width: 100%;
    border-radius: 5px;
}

