.hotline{
    display: none;
    position: fixed;
    z-index: 99999;
    bottom: 15%
}
@media (max-width: 700px) {
    .hotline{
        display: block;
    }
}